import {
  Box,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useRef } from 'react';

interface MobileTooltipProps {
  children: ReactNode;
  tooltip: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

const TdWithTooltip: React.FC<MobileTooltipProps> = ({
  children,
  tooltip,
  placement = 'top',
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const triggerRef = useRef<HTMLDivElement>(null);

  const bgColor = useColorModeValue('rgba(0, 0, 0, 0.90)', 'gray.800');
  const textColor = useColorModeValue('white', 'white');

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isMobile && isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.addEventListener('mousedown', handleClickOutside);
    }
  }, [isMobile, isOpen, onClose]);

  const handleInteraction = (e: React.MouseEvent) => {
    if (isMobile) {
      e.preventDefault();
      isOpen ? onClose() : onOpen();
    }
  };

  return (
    <Tooltip
      isOpen={isOpen}
      label={tooltip}
      placement={placement}
      hasArrow
      bg={bgColor}
      color={textColor}
      fontSize="sm"
      py={2}
      px={3}
      borderRadius="md"
      boxShadow="lg"
      gutter={8}
      onClose={onClose}
      isDisabled={isMobile} // Disable default tooltip behavior on mobile
    >
      <Box
        ref={triggerRef}
        onClick={handleInteraction}
        onMouseEnter={() => !isMobile && onOpen()}
        onMouseLeave={() => !isMobile && onClose()}
        cursor="pointer"
        display="inline-block"
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default TdWithTooltip;
