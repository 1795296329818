import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { fetchClientsInfo } from '../../api/InvestorApis/apis';
import chart from '../../assets/chart-pie.png';
import info from '../../assets/info.png';
import { ClientInfoResponse } from '../../interfaces/components';
import Chart from './Chart';
import Graph from './Graph';
import Graph2 from './Graph2';
import Invesment from './Invesment';
import Payout from './Payout';
import Switcher from './Switcher';

type Tab = 'Allocation' | 'Investments';
const Dashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab>('Investments');
  const toast = useToast();

  const [clientsInfo, setClientsInfo] = useState<ClientInfoResponse>();
  const fetchClientInfo = async () => {
    try {
      const res = await fetchClientsInfo();

      setClientsInfo(res);
    } catch (err: any) {
      toast({
        title: 'Something went wrong while fetching payouts',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    fetchClientInfo();
  }, []);
  const handleSwitch = (tab: Tab) => {
    setActiveTab(tab);
  };
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Flex
        marginTop={['14px', '14px', '14px', '20px']}
        marginBottom={'20px'}
        width={'100%'}
        display={['none', 'none', 'flex']}
      >
        <Text
          paddingLeft={['12px', '16px', '0px']}
          paddingRight={['12px', '16px', '20px']}
          fontSize={['20px', '22px', '24px', '36px']}
          fontWeight={700}
          fontFamily="Montserrat"
          textColor={'#1A1A1A'}
        >
          Welcome to your Dashboard! 👋
        </Text>
      </Flex>
      <Box display={'flex'} gap={'18px'} flexDirection={'column'}>
        <Box
          display={'flex'}
          gap={['0px', '0px', '18px']}
          flexDir={['column', 'column', 'column', 'row']}
        >
          <Box flex={'1 0 0'}>
            <Box
              display={'flex'}
              flexDir={'column'}
              justifyContent={'space-between'}
              borderTopRadius={['0px ', '0px', '44px']}
              background={'#FFF'}
              borderBottom={'transparent'}
              boxShadow={[
                'none',
                'none',
                '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
              ]}
              padding={'22px'}
              marginBottom={'-2px'}
            >
              <Flex
                flexDirection={'column'}
                gap={['22px', '22px', '22px', '0px']}
              >
                <Box
                  display={'flex'}
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'space-between',
                  ]}
                  flexDir={['column', 'column', 'column', 'row']}
                  gap={['16px', '16px', '16px', '0px']}
                >
                  <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                    <Image src={chart} alt="" width={'28px'} height={'28px'} />
                    <Box display={'flex'} gap={'4px'} alignItems={'center'}>
                      <Text
                        fontSize={'16px'}
                        fontWeight={600}
                        fontFamily="Montserrat"
                        whiteSpace={['nowrap', 'nowrap', 'nowrap', 'normal']}
                      >
                        {activeTab === 'Allocation'
                          ? 'Allocation'
                          : 'Investment Tracker'}
                      </Text>
                      <Image src={info} alt="" width={'20px'} height={'20px'} />
                    </Box>
                  </Box>
                  <Switcher onSwitch={handleSwitch} activeTab={activeTab} />
                </Box>
              </Flex>
            </Box>
            {activeTab === 'Investments' ? (
              <Invesment clientInfo={clientsInfo} />
            ) : (
              <Chart clientInfo={clientsInfo} />
            )}
          </Box>
          <Box
            display={'flex'}
            flexDir={'column'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            flex={'1 0 0'}
            borderRadius={['0px ', '0px', '44px']}
            border={['transparent', 'transparent', '1px solid #EFEFEF']}
            borderBottom={'1px solid #EFEFEF'}
            background={'#FFF'}
            boxShadow={[
              'none',
              'none',
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
            ]}
          >
            <Graph />
          </Box>
        </Box>
        <Graph2 />
        <Payout clientInfo={clientsInfo} />
      </Box>
    </Box>
  );
};

export default Dashboard;
