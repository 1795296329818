import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sucess from '../../assets/verified_user.png';
import RequestFailedModal from './RequestFailedModal';

interface PasswordSucessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PasswordSucessModal({
  isOpen,
  onClose,
}: PasswordSucessModalProps) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '404px', '500px', '500px']}
        borderRadius="20px"
        padding="22px"
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalBody padding="0px">
          <Box gap={'16px'} display={'flex'} flexDirection={'column'}>
            <Box
              display={'flex'}
              flexDir={'column'}
              justifyContent={'center'}
              gap={'12px'}
            >
              <Text
                color="#1A1A1A"
                fontFamily="Montserrat"
                fontSize={['16px', '28px', '20px']}
                fontStyle="normal"
                fontWeight="700"
                lineHeight="normal"
                alignSelf={'center'}
              >
                Password reset successful
              </Text>
            </Box>
            <Box justifyContent={'center'} display={'flex'}>
              <Box
                display="flex"
                padding="20px"
                alignItems="center"
                gap="10px"
                borderRadius="64px"
                background="var(--Color-Green-50, #E6F9EB)"
                w={'fit-content'}
                alignSelf={'center'}
              >
                <Image
                  src={Sucess}
                  alt="Image"
                  width="54px"
                  height="54px"
                  onClick={() => setIsModalOpen(true)}
                />
              </Box>
            </Box>

            <Box display={'flex'} justifyContent={'center'}>
              <Text
                color="#8C8C8C"
                fontFamily="Montserrat"
                fontSize={['10px', '14px', '14px']}
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                whiteSpace={'nowrap'}
                alignSelf={'center'}
              >
                Click here to{' '}
                <span
                  style={{ color: '#0FBF95', cursor: 'pointer' }}
                  onClick={() => navigate('/')}
                >
                  Login
                </span>
              </Text>
            </Box>
          </Box>
          <RequestFailedModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
