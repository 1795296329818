import { Box, Text } from '@chakra-ui/react';
import 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import Dollar from '../../assets/Dollar.svg';
import Info from '../../assets/Info.svg';
import TdWithTooltip from '../LiquadationTrackerNew/TdWithTooltip';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { getLiquidation } from '../../api/InvestorApis/liquidity';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface LiquidationData {
  liquidate_date: string;
  profit: number;
}

interface GraphDataItem {
  profit: number;
  loss: number;
  total: number;
  change: number;
  date: Date | null;
}

interface ProcessedData {
  results: LiquidationData[];
}

// Helper functions
const aggregateChangesByDate = (data: GraphDataItem[]): GraphDataItem[] => {
  const aggregated: Record<string, GraphDataItem> = {};

  data.forEach((item) => {
    const dateString = item.date?.toDateString() || '';
    if (!aggregated[dateString]) {
      aggregated[dateString] = {
        profit: 0,
        loss: 0,
        total: 0,
        change: 0,
        date: item.date,
      };
    }
    aggregated[dateString].change += item.change;
    aggregated[dateString].profit += Math.max(item.change, 0);
    aggregated[dateString].loss += Math.abs(Math.min(item.change, 0));
    aggregated[dateString].total += item.change;
  });

  return Object.values(aggregated);
};

const generateDateRange = (days: number): Date[] => {
  const dates: Date[] = [];
  const today = new Date();
  for (let i = days - 1; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(date.getDate() - i);
    dates.push(date);
  }
  return dates;
};

const Graph: React.FC = () => {
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number>(0);
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>(6);
  const timeRanges: string[] = ['7 Days', '30 Days', '90 Days', 'All'];
  const [dates, setDates] = useState<Date[]>([]);
  const [graphData, setGraphData] = useState<GraphDataItem[]>([]);
  const [liquidateData, setLiquidateData] = useState<ProcessedData | null>(
    null,
  );
  // Fetch data from API
  const fetchLiquidationData = async () => {
    try {
      const liquidationData = await getLiquidation(null);
      setLiquidateData(liquidationData);
    } catch (error) {
      console.error('Error fetching liquidation data:', error);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchLiquidationData();
  }, []);

  useEffect(() => {
    if (!liquidateData?.results?.length) return;

    let daysToGenerate = 7;
    switch (selectedTimeIndex) {
      case 0:
        daysToGenerate = 7;
        break;
      case 1:
        daysToGenerate = 30;
        break;
      case 2:
        daysToGenerate = 90;
        break;
      case 3:
        daysToGenerate = 120;
        break;
    }

    // Process dummy data into required format
    let processedData: GraphDataItem[] = liquidateData.results.map((item) => {
      const currentProfit = item.profit;
      const change = currentProfit;
      const profit = Math.max(change, 0);
      const loss = Math.abs(Math.min(change, 0));

      return {
        profit,
        loss,
        total: currentProfit,
        change,
        date: new Date(item.liquidate_date),
      };
    });

    processedData = aggregateChangesByDate(processedData);
    const completeDateRange = generateDateRange(daysToGenerate);
    const filledData = completeDateRange.map((date) => {
      const existingData = processedData.find(
        (item) => item.date?.toDateString() === date.toDateString(),
      );

      return (
        existingData || {
          profit: 0,
          loss: 0,
          total: 0,
          change: 0,
          date,
        }
      );
    });

    setGraphData(filledData);
    let test = 0;
    filledData.forEach((data) => {
      test += data.total;
      console.log(test);
    });
    setDates(filledData.map((item) => item.date!));
    setSelectedDayIndex(Math.min(filledData.length - 1, daysToGenerate - 1));
  }, [selectedTimeIndex, liquidateData]);

  const selectedDayChange = graphData.reduce((prev, acc) => {
    return prev + acc.total;
  }, 0);
  const isSelectedDayProfit = selectedDayChange >= 0;

  // Chart data preparation
  const labels = dates.map((date) =>
    date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
  );

  const data = {
    labels,
    datasets: [
      {
        label: 'Profit/Loss',
        data: graphData.map((item) => item.change),
        backgroundColor: (context: any) => {
          const value = context.raw;
          return value >= 0
            ? 'rgba(75, 192, 192, 0.6)'
            : 'rgba(255, 99, 132, 0.6)';
        },
        borderColor: (context: any) => {
          const value = context.raw;
          return value >= 0 ? 'rgba(75, 192, 192, 1)' : 'rgba(255, 99, 132, 1)';
        },
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            return value >= 0
              ? `Profit: $${value.toFixed(2)}`
              : `Loss: $${Math.abs(value).toFixed(2)}`;
          },
        },
      },
    },
    onClick: (_event: any, elements: any) => {
      if (elements && elements.length > 0) {
        setSelectedDayIndex(elements[0].index);
      }
    },
  };

  return (
    <Box
      display="flex"
      padding="22px"
      flexDirection="column"
      gap="16px"
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius="44px"
      borderColor="#F6F6F6"
      backgroundColor="#FFF"
      justifyContent="space-between"
      width={'100%'}
    >
      {/* Header Section */}
      <Box
        display="flex"
        alignItems="center"
        rowGap="16px"
        alignSelf="stretch"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center" gap="8px">
          <Box justifyContent="left" display="flex" width="100%">
            <img
              src={Dollar}
              alt="Dollar Icon"
              style={{ marginRight: '8px' }}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="4px"
            >
              <Text
                color="#1A1A1A"
                textAlign="center"
                fontFamily="Visby CF, sans-serif"
                fontSize="24px"
                fontWeight="600"
                lineHeight="normal"
                sx={{
                  '@media screen and (min-width: 768px)': { display: 'block' },
                  '@media screen and (max-width: 767px)': { display: 'none' },
                }}
              >
                Profit/Loss History
              </Text>
              <Text
                color="#1A1A1A"
                textAlign="center"
                fontFamily="Visby CF, sans-serif"
                fontSize="24px"
                fontWeight="600"
                lineHeight="normal"
                sx={{
                  '@media screen and (min-width: 768px)': { display: 'none' },
                  '@media screen and (max-width: 767px)': { display: 'block' },
                }}
              >
                Profit/Loss History
                {/* {isSelectedDayProfit ? 'Profit' : 'Loss'} */}
              </Text>
              <TdWithTooltip tooltip="This is your net profit or loss on transactions per day">
                <img src={Info} alt="Info Icon" />
              </TdWithTooltip>
            </Box>
          </Box>
        </Box>

        {/* Time Range Selector */}
        <Box width="100%" display="flex" justifyContent="center">
          <Box
            width={['', '100%']}
            display="flex"
            padding="4px"
            alignItems="flex-start"
            borderRadius="24px"
            background="#F2F2F2"
          >
            {timeRanges.map((option, index) => (
              <Box
                key={index}
                flex={1}
                display="flex"
                padding="10px 20px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius="20px"
                background={selectedTimeIndex === index ? '#FFF' : '#F2F2F2'}
                onClick={() => setSelectedTimeIndex(index)}
                cursor="pointer"
              >
                <Text
                  color={selectedTimeIndex === index ? '#0FBF95' : '#000'}
                  fontFamily="Montserrat"
                  fontSize={['12px', '12px', '16px']}
                  fontStyle="normal"
                  margin="0"
                  fontWeight="600"
                  lineHeight="20px"
                  whiteSpace="nowrap"
                >
                  {option}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Selected Day Info */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
        padding={['0px', '20px']}
        background="#FFF"
        borderRadius="8px"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          gap="8px"
        >
          <Text
            color={isSelectedDayProfit ? '#39D05D' : '#FF6384'}
            fontSize={['24px', '24px', '42px']}
            fontStyle="normal"
            fontWeight="600"
            margin="0"
            lineHeight="52px"
            letterSpacing="-1.26px"
            fontFamily="Montserrat"
          >
            ${Math.abs(selectedDayChange).toFixed(2)}
          </Text>
          <Text
            color="#8C8C8C"
            textAlign="center"
            fontFamily="Montserrat"
            fontSize={['12px', '12px', '18px']}
            fontStyle="normal"
            margin="0"
            fontWeight="500"
            lineHeight="18px"
          >
            {isSelectedDayProfit ? 'Profit' : 'Loss'} in{' '}
            {timeRanges[selectedTimeIndex] || ''}
          </Text>
        </Box>
        <Text
          color="#8C8C8C"
          margin="0"
          textAlign="center"
          fontFamily="Montserrat"
          fontSize={['14px', '18px']}
          fontStyle="normal"
          fontWeight="500"
          lineHeight="18px"
        >
          {dates[selectedDayIndex]?.toLocaleString('default', {
            month: 'long',
            year: 'numeric',
          })}
        </Text>
      </Box>

      {/* Chart */}
      <Box
        width="100%"
        height="fit-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default Graph;
