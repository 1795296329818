import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import payoutfram from '../../assets/Frame.png';
import info from '../../assets/info.png';
import polygon from '../../assets/Polygon.png';
import topimg from '../../assets/track_changes_bg_white.png';
import { NonPaginatedPayoutResponse } from '../../interfaces/components';

type YearData = {
  month: string;
  weeks: number[];
};

type DataType = {
  [key: number]: YearData[];
};
const YearPagination: React.FC<{
  selectedYear: number;
  onYearChange: (year: number) => void;
}> = ({ selectedYear, onYearChange }) => {
  const years = [2022, 2023, 2024];

  return (
    <HStack spacing={['10px']}>
      <IconButton
        aria-label="Previous year"
        icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
        onClick={() => onYearChange(selectedYear - 1)}
        isDisabled={selectedYear <= 2022}
        background={'transparent'}
        _hover={{
          background: 'gray.100',
          borderRadius: 'full',
        }}
        transition="all 0.2s"
      />
      {years.map((year) => (
        <Text
          key={year}
          fontWeight={'500'}
          fontSize={['17px', '18px']}
          lineHeight={'20px'}
          color={year === selectedYear ? '#0FBF95' : '#8C8C8C'}
          cursor="pointer"
          onClick={() => onYearChange(year)}
        >
          {year}
        </Text>
      ))}
      <IconButton
        aria-label="Next year"
        icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
        onClick={() => onYearChange(selectedYear + 1)}
        isDisabled={selectedYear >= 2024}
        background={'transparent'}
        _hover={{
          background: 'gray.100',
          borderRadius: 'full',
        }}
        transition="all 0.2s"
      />
    </HStack>
  );
};

const CumulativePayoutsChart: React.FC<{
  payouts: NonPaginatedPayoutResponse | undefined;
}> = ({ payouts }) => {
  const transformPayoutData = (
    payouts: NonPaginatedPayoutResponse | undefined,
  ): DataType => {
    const data: DataType = {};
    // Initialize all 12 months with zeroed weeks
    // Initialize all 12 months with zeroed weeks
    const initializeYearData = (): YearData[] => {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return months.map((month) => ({
        month,
        weeks: [0, 0, 0, 0],
      }));
    };

    payouts?.results?.forEach((payout) => {
      const date = new Date(payout.date);
      const year = date.getFullYear();
      const month = date.toLocaleString('default', { month: 'short' });
      const week = Math.ceil(date.getDate() / 7);

      if (!data[year]) {
        data[year] = initializeYearData();
      }

      let monthData = data[year].find((m) => m.month === month);
      if (!monthData) {
        monthData = { month, weeks: [0, 0, 0, 0] };
        data[year].push(monthData);
      }

      monthData.weeks[week - 1] += 1;
    });

    return data;
  };
  const data = transformPayoutData(payouts);
  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [hoveredBar, setHoveredBar] = useState<{
    month: string;
    week: number;
  } | null>(null);
  const [startIndex, setStartIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const chartRef = useRef<HTMLDivElement>(null);

  const [colorPrimary200, colorPrimary500, colorNeutral200] = useToken(
    'colors',
    ['#9FEAD5', '#0FBF95', '#E2E2E2'],
  );

  const yAxisValues = [0, 5, 10, 15, 20, 25];

  const calculateBarHeight = (value: number) => {
    const maxYAxisValue = Math.max(...yAxisValues);
    return (value / maxYAxisValue) * 250; // 250px is the total height of the chart
  };

  const calculateTotalPayouts = (year: number) => {
    return data[year]?.reduce((total, month) => {
      return (
        total +
        month.weeks.reduce((sum, week) => {
          // Check if the week value is a valid number; if not, add 0
          return sum + (typeof week === 'number' && !isNaN(week) ? week : 0);
        }, 0)
      );
    }, 0);
  };

  const [totalPayouts, setTotalPayouts] = useState(0);

  const visibleMonths = data[selectedYear]?.slice(startIndex, startIndex + 4);

  useEffect(() => {
    setStartIndex(0);
    setTotalPayouts(calculateTotalPayouts(selectedYear));
  }, [selectedYear]);
  useEffect(() => {
    setTotalPayouts(calculateTotalPayouts(selectedYear));
  }, [data]);

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    setDragStartX('touches' in e ? e.touches[0].clientX : e.clientX);
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;

    const currentX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const diff = dragStartX - currentX;

    if (Math.abs(diff) > 50) {
      // Threshold for drag distance
      if (diff > 0 && startIndex < data[selectedYear]?.length - 4) {
        setStartIndex((prevIndex) => prevIndex + 1);
      } else if (diff < 0 && startIndex > 0) {
        setStartIndex((prevIndex) => prevIndex - 1);
      }
      setDragStartX(currentX);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <Box
      padding={'22px'}
      display={'flex'}
      gap={'21px'}
      border={'1px solid #F6F6F6'}
      borderRadius={'44px'}
      background={'#FFF'}
      flexDirection={'column'}
      flex={'1'}
    >
      <HStack
        justifyContent={['normal', 'normal', 'normal', 'space-between']}
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
        gap={['14px', '14px', '14px', '0px']}
      >
        <HStack>
          <Image
            src={topimg}
            alt=""
            width={['26px', '28px']}
            height={['26px', '28px']}
            background={'white'}
          />
          <Text
            color={'#1A1A1A'}
            fontSize={['22px', '24px']}
            fontWeight={'600'}
            fontFamily="Montserrat"
          >
            Cumulative payouts
          </Text>
          <Image src={info} alt="" height={'20px'} width={'20px'} />
        </HStack>

        <YearPagination
          selectedYear={selectedYear}
          onYearChange={(year) => setSelectedYear(year)}
        />
      </HStack>
      <HStack justifyContent={'space-between'}>
        <VStack alignItems={'flex-start'}>
          <Text
            color={'#0FBF95'}
            fontSize={'42px'}
            fontWeight={'600'}
            lineHeight={'52px'}
            letterSpacing={'-1.26px'}
          >
            {totalPayouts}
          </Text>
          <Text
            color={'#8C8C8C'}
            fontSize={['17px', '18px']}
            lineHeight={'18px'}
            fontWeight={'500'}
            fontFamily="Montserrat"
          >
            Total payouts this year
          </Text>
        </VStack>
        <HStack alignItems={'start'} gap={'4px'}>
          <Image
            src={payoutfram}
            width={'20px'}
            height={'20px'}
            marginBottom={'2px'}
          />
          <Text
            color={'#8C8C8C'}
            fontSize={'18px'}
            fontWeight={'500'}
            lineHeight={'18px'}
            fontFamily="Montserrat"
          >
            Payouts
          </Text>
        </HStack>
      </HStack>
      <VStack spacing={4} align="stretch">
        <Flex justifyContent="flex-end" gap={'10px'}>
          <Box
            overflow="hidden"
            width="calc(100% - 30px)"
            padding={['0px 13px', '0px 10px']}
            ref={chartRef}
            onMouseDown={handleDragStart}
            onMouseMove={handleDragMove}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}
            onTouchStart={handleDragStart}
            onTouchMove={handleDragMove}
            onTouchEnd={handleDragEnd}
            cursor={isDragging ? 'grabbing' : 'grab'}
          >
            <Flex
              flexDirection="column"
              width="100%"
              transition="transform 0.2s ease-out"
            >
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="flex-end"
                height="250px"
              >
                {visibleMonths?.flatMap(({ month, weeks }) =>
                  weeks.map((value, weekIndex) => (
                    <VStack
                      key={`${month}-${weekIndex}`}
                      spacing={3}
                      alignItems="center"
                      width={['6px', '15px', '8px', '13px', '20px']}
                    >
                      <Box
                        minWidth={'30px'}
                        padding={'6px 12px'}
                        borderRadius={'21px'}
                        background={'#1A1A1A'}
                        position={'relative'}
                        visibility={
                          selectedMonth === month &&
                          hoveredBar?.month === month &&
                          hoveredBar?.week === weekIndex
                            ? 'visible'
                            : 'hidden'
                        }
                      >
                        <Text
                          fontSize={'16px'}
                          lineHeight={'16px'}
                          color={'#FFF'}
                          fontWeight={'600'}
                        >
                          {value}
                        </Text>
                        <Image
                          src={polygon}
                          height={'10px'}
                          width={'14px'}
                          position={'absolute'}
                          bottom={'-6px'}
                          left={'50%'}
                          transform={'translateX(-50%)'}
                        />
                      </Box>
                      <Box
                        h={`${calculateBarHeight(value)}px`}
                        width={['6px', '15px', '8px', '13px', '20px']}
                        borderRadius="8px"
                        bg={
                          selectedMonth === month
                            ? hoveredBar?.month === month &&
                              hoveredBar?.week === weekIndex
                              ? colorPrimary500
                              : colorPrimary200
                            : colorNeutral200
                        }
                        onClick={() => {
                          setSelectedMonth(month);
                        }}
                        onMouseEnter={() =>
                          setHoveredBar({ month, week: weekIndex })
                        }
                        onMouseLeave={() => setHoveredBar(null)}
                        cursor="pointer"
                        transition="background-color 0.2s"
                      />
                    </VStack>
                  )),
                )}
              </Flex>

              <Box
                height="0px"
                width="100%"
                borderTop="1px solid"
                borderColor="var(--Color-Neutral-200, #E2E2E2)"
                my={2}
              />

              <Flex width="100%" justifyContent="space-between" mt={2}>
                {visibleMonths?.map(({ month }, index) => (
                  <Box
                    key={month}
                    width={`${100 / visibleMonths.length - 1}%`}
                    textAlign="center"
                    ml={index === 0 ? 0 : '1%'}
                  >
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="full"
                      bg={selectedMonth === month ? 'black' : 'transparent'}
                      minWidth={'60px'}
                      h="30px"
                      transition="all 0.2s"
                    >
                      <Text
                        fontSize={['16px']}
                        color={selectedMonth === month ? 'white' : '#8C8C8C'}
                        fontWeight={'600'}
                        lineHeight={'16px'}
                        transition="color 0.2s"
                      >
                        {month}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </Flex>
            </Flex>
          </Box>

          <VStack justifyContent="space-between" h="250px" pl={2} width="30px">
            {yAxisValues.reverse().map((value) => (
              <Text
                color={'#8C8C8C'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'16px'}
                key={value}
              >
                {value}
              </Text>
            ))}
          </VStack>
        </Flex>
      </VStack>
    </Box>
  );
};

export default CumulativePayoutsChart;
