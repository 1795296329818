import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import App from './App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
const queryClient = new QueryClient();
root.render(
    <QueryClientProvider client={queryClient}>
        <App/>
    </QueryClientProvider>
);

reportWebVitals();
