interface GraphDataItem {
  total: number;
  change: number;
  profit:number;
  loss:number;
  date: Date | null;
}
export function formatAddress(hexString: string) {
  const prefix = hexString.substring(0, 8); // Get the first 8 characters
  const suffix = hexString.substring(hexString.length - 8); // Get the last 8 characters
  return prefix + '......' + suffix; // Concatenate with dots in between
}

export const formatDateForInput = (dateString: string | number | Date) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().slice(0, 16); // Format as "YYYY-MM-DDTHH:MM"
};
export const formateDate = (dateString: string ) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString();
};
export function aggregateChangesByDate(data: GraphDataItem[]) {
  // Use a Map to store aggregated changes by date
  const aggregated = new Map<string, { date: Date; total: number; change: number;profit:number;loss:number }>();

  data.forEach(entry => {
      // Extract date part (YYYY-MM-DD) from the timestamp
      const date = entry.date? entry.date.toISOString().split('T')[0] : "";

      if (aggregated.has(date)) {
        const existing = aggregated.get(date)!;
        // Update total and sum up the changes
        existing.total += entry.total;
        existing.change += entry.change;
        existing.profit =  existing.total >=0 ? existing.total : 0;
        existing.loss =  existing.total < 0 ? Math.abs(existing.total) : 0;
    } else {
        // Initialize with current total and change
        aggregated.set(date, {
            date: new Date(date),
            total: entry.total,
            change: entry.change,
            profit: entry.total >=0 ? entry.total : 0,
            loss: entry.total < 0 ? Math.abs(entry.total) : 0,
        });
    }
  });

  // Convert the Map back to an array of objects
  return Array.from(aggregated.values())
}

export function hasDatePassed(dateString:string) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    return inputDate < currentDate;
}
export const generateDateRange = (days:number) => {
  const today = new Date();
  const dates = [];
  for (let i = 0; i < days; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - (days - 1 - i)); // go back 'days' and fill the range
    dates.push(date);
  }
  return dates;
};
export function updateCountdown(targetDate:string) {
  const t_date = new Date(targetDate);
  const now = new Date();
  let diff = t_date.getTime() - now.getTime();

  // Days countdown
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (days > 1) {
      return `${days} days`;
  }

  // Hours countdown
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  if (hours >= 1) {
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} hours`;
  }

  // Minutes countdown
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  if (minutes >= 1) {
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} mins`;
  }

  // Seconds countdown
  const seconds = Math.floor(diff / 1000);
  return `${String(seconds).padStart(2, '0')} secs`;
}
