import { Box, Text } from '@chakra-ui/react';
import { Chart, ChartConfiguration, ChartOptions } from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import Dollar from '../../assets/Dollar.svg';
import Info from '../../assets/Info.svg';
import TdWithTooltip from '../LiquadationTrackerNew/TdWithTooltip';

const Graph2: React.FC = () => {
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number>(0);
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>(6);
  const timeRanges: string[] = ['7D', '30D', '90D', 'All'];
  const chartRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  let chartInstance: Chart | null = null;

  // Generate dummy data based on selected time range
  const generateData = (days: number) => {
    const data: { x: number; y: number; label: string }[] = [];
    let prev = 100;
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const today = new Date();

    for (let i = 0; i < days; i++) {
      const date = new Date();
      date.setDate(today.getDate() - (days - i - 1));
      const day = date.getDate();
      const month = months[date.getMonth()];
      const label = `${day}${month}`;

      prev += 5 - Math.random() * 10;
      data.push({ x: i, y: prev, label: label });
    }
    return data;
  };

  const getDataLength = () => {
    switch (selectedTimeIndex) {
      case 0:
        return 7;
      case 1:
        return 30;
      case 2:
        return 90;
      case 3:
        return 365;
      default:
        return 7;
    }
  };

  const data = generateData(getDataLength());
  const selectedDayChange =
    data[selectedDayIndex]?.y - data[selectedDayIndex - 1]?.y || 0;
  const isSelectedDayProfit = selectedDayChange >= 0;

  const resizeChart = () => {
    if (chartInstance && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      chartInstance.canvas.style.width = `${containerWidth}px`;
      chartInstance.canvas.style.height = '400px';
      chartInstance.resize();
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      const totalDuration = 20000;
      const delayBetweenPoints = totalDuration / data.length;

      const previousY = (ctx: any) =>
        ctx.index === 0
          ? ctx.chart.scales.y.getPixelForValue(100)
          : ctx.chart
              .getDatasetMeta(ctx.datasetIndex)
              .data[ctx.index - 1].getProps(['y'], true).y;

      const animation = {
        x: {
          type: 'number',
          easing: 'linear',
          duration: delayBetweenPoints,
          from: NaN,
          delay(ctx: any) {
            if (ctx.type !== 'data' || ctx.xStarted) {
              return 0;
            }
            ctx.xStarted = true;
            return (ctx.index * delayBetweenPoints) / 4;
          },
        },
        y: {
          type: 'number',
          easing: 'easeOutQuart',
          duration: delayBetweenPoints,
          from: previousY,
          delay(ctx: any) {
            if (ctx.type !== 'data' || ctx.yStarted) {
              return 0;
            }
            ctx.yStarted = true;
            return (ctx.index * delayBetweenPoints) / 4;
          },
        },
      };

      const config: ChartConfiguration = {
        type: 'line',
        data: {
          labels: data.map((item) => item.label),
          datasets: [
            {
              borderColor: '#00C950',
              borderWidth: 1,
              radius: 0,
              data: data.map((item) => item.y),
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation,
          interaction: {
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                labelColor: () => ({
                  borderColor: '#00C950',
                  backgroundColor: '#00C950',
                }),
              },
            },
          },
          scales: {
            x: {
              ticks: {
                maxRotation: 45,
                color: '#8C8C8C',
                font: {
                  size: 12,
                  family: 'Montserrat',
                },
                callback: function (_, index) {
                  // Show fewer labels for better readability when there are many data points
                  const skipLabels = data.length > 30 ? 5 : 1;
                  return index % skipLabels === 0 ? data[index].label : '';
                },
              },
              grid: {
                display: true, // Show vertical grid lines
                color: '#E2E2E2', // Set grid line color
                drawBorder: false, // Don't draw border
                drawOnChartArea: true, // Draw on chart area
                drawTicks: false, // Don't draw ticks
                lineWidth: 1, // Grid line width
              },
            },
            y: {
              grid: {
                color: '#E2E2E2',
              },
              ticks: {
                color: '#8C8C8C',
                font: {
                  size: 12,
                  family: 'Montserrat',
                },
              },
            },
          },
          onClick: (_event, elements) => {
            if (elements && elements.length > 0) {
              setSelectedDayIndex(elements[0].index);
            }
          },
        } as ChartOptions,
      };

      if (chartInstance) {
        chartInstance.destroy();
      }
      chartInstance = new Chart(chartRef.current, config);

      // Initial resize
      resizeChart();

      // Add window resize listener
      window.addEventListener('resize', resizeChart);

      return () => {
        if (chartInstance) {
          chartInstance.destroy();
        }
        window.removeEventListener('resize', resizeChart);
      };
    }
  }, [data, selectedTimeIndex]);
  return (
    <Box
      display="flex"
      padding="22px"
      flexDirection="column"
      gap="16px"
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius={['0px', '0px', '44px']}
      borderColor="#F6F6F6"
      backgroundColor="#FFF"
      justifyContent="space-between"
      width="100%"
      borderBottom={['1px solid #EFEFEF', '1px solid #EFEFEF', 'none']}
    >
      {/* Header Section */}
      <Box
        display="flex"
        alignItems="center"
        rowGap="16px"
        alignSelf="stretch"
        flexWrap="wrap"
        justifyContent={'space-between'}
      >
        <Box display="flex" alignItems="center" gap="8px">
          <Box justifyContent="left" display="flex" width="100%">
            <img
              src={Dollar}
              alt="Dollar Icon"
              style={{ marginRight: '8px' }}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="4px"
            >
              <Text
                color="#1A1A1A"
                textAlign="center"
                fontFamily="Visby CF, sans-serif"
                fontSize="24px"
                fontWeight="600"
                lineHeight="normal"
                sx={{
                  '@media screen and (min-width: 768px)': { display: 'block' },
                  '@media screen and (max-width: 767px)': { display: 'none' },
                }}
              >
                Portfolio history
              </Text>
              <Text
                color="#1A1A1A"
                textAlign="center"
                fontFamily="Visby CF, sans-serif"
                fontSize="24px"
                fontWeight="600"
                lineHeight="normal"
                sx={{
                  '@media screen and (min-width: 768px)': { display: 'none' },
                  '@media screen and (max-width: 767px)': { display: 'block' },
                }}
              >
                Profit/Loss History
              </Text>
              <TdWithTooltip tooltip="This is your net profit or loss on transactions per day">
                <img src={Info} alt="Info Icon" />
              </TdWithTooltip>
            </Box>
          </Box>
        </Box>

        {/* Time Range Selector */}
        {/* <Box
          display={'flex'}
          padding={'11px 14px'}
          alignItems={'center'}
          gap={'4px'}
          borderRadius={'21px'}
          border={'1px solid #E2E2E2'}
        >
          <Text>All investments</Text>
          <img
            style={{ width: '14px', height: '14px' }}
            src={dropdown}
            alt="dropdown"
          />
        </Box> */}
      </Box>

      {/* Selected Day Info */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
        // padding={['0px', '20px']}
        background="#FFF"
        borderRadius="8px"
        flexDirection={['column-reverse', 'column-reverse', 'row']}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          gap="8px"
          alignSelf={['start', 'start', 'none']}
        >
          <Text
            color={isSelectedDayProfit ? '#39D05D' : '#FF6384'}
            fontSize={['24px', '24px', '42px']}
            fontStyle="normal"
            fontWeight="600"
            margin="0"
            lineHeight="52px"
            letterSpacing="-1.26px"
            fontFamily="Montserrat"
          >
            ${Math.abs(selectedDayChange).toFixed(2)}
          </Text>
          <Text
            color="#8C8C8C"
            textAlign="center"
            fontFamily="Montserrat"
            fontSize={['12px', '12px', '18px']}
            fontStyle="normal"
            margin="0"
            fontWeight="500"
            lineHeight="18px"
          >
            Your current position
          </Text>
        </Box>
        <Box
          width="fit-content"
          display="flex"
          justifyContent="center"
          alignSelf={'start'}
        >
          <Box
            width={['100%', '100%']}
            display="flex"
            padding="4px"
            alignItems="flex-start"
            borderRadius="24px"
            background="#F2F2F2"
          >
            {timeRanges.map((option, index) => (
              <Box
                key={index}
                flex={1}
                display="flex"
                padding="10px 20px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius="20px"
                background={selectedTimeIndex === index ? '#FFF' : '#F2F2F2'}
                onClick={() => setSelectedTimeIndex(index)}
                cursor="pointer"
              >
                <Text
                  color={selectedTimeIndex === index ? '#0FBF95' : '#000'}
                  fontFamily="Montserrat"
                  fontSize={['12px', '12px', '16px']}
                  fontStyle="normal"
                  margin="0"
                  fontWeight="600"
                  lineHeight="20px"
                  whiteSpace="nowrap"
                >
                  {option}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Chart Container */}
      <Box ref={containerRef} width="100%" height="400px" position="relative">
        <canvas ref={chartRef}></canvas>
      </Box>
    </Box>
  );
};

export default Graph2;
