import {
  Box,
  Button,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AppStoreState, useAppStore } from '../store';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import ClientsLogo from '../Icons/ClientsLogo';
import { getPayouts } from '../api/InvestorApis/apis';
import Countdown from '../components/Countdowm';
import ClaimTokenModal from '../components/claimToken/ClaimTokenModal';

export default function Payouts() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [payloadId, setPayloadId] = useState('');

  const loading = useAppStore((state: AppStoreState) => state.loading);
  const payoutList = useAppStore((state: AppStoreState) => state.payoutList);
  const setPayoutList = useAppStore(
    (state: AppStoreState) => state.setPayoutList,
  );
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const itemsPerPage =
    payoutList &&
    payoutList?.results?.length > 0 &&
    payoutList?.results?.length;
  const totalPages = itemsPerPage && payoutList && payoutList?.total_pages;

  const fetchPayouts = async (page: number) => {
    try {
      setLoading(true);
      const res = await getPayouts(true,page);
      if (res) {
        setPayoutList(res);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching payouts',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    fetchPayouts(1);
  }, [isOpen]);

  const handlePrevPage = () => {
    payoutList?.current_page && fetchPayouts(payoutList?.current_page - 1);
  };

  const handleNextPage = () => {
    payoutList?.current_page && fetchPayouts(payoutList?.current_page + 1);
  };
  return (
    <>
      <ClaimTokenModal
        isOpen={isOpen}
        onClose={() => {
          setLoading(false);
          onClose();
          setPayloadId('');
        }}
        payoutId={payloadId}
      />
      {loading ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Box>
      ) : (
        <Box width="100%">
          <Box pb="30px" display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={3}
            >
              <Text
                fontFamily="Montserrat"
                fontWeight="700"
                fontSize={['16px', '18px', '20px', '36px', '36px']}
                color="#1A1A1A"
                marginLeft={['20px', '20px', 'unset']}
              >
                Stay updated with your payouts
              </Text>
              <ClientsLogo />
            </Box>
          </Box>

          {/* start wokzone */}

          <Box
            bg="white"
            borderRadius="20px"
            border="1px solid #E2E2E2"
            padding="16px"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                fontFamily="Montserrat"
                fontSize="24px"
                fontWeight="600"
                color="#1A1A1A"
              >
                Payouts
              </Text>
            </Box>

            <TableContainer marginTop="10px">
              <Table variant="simple">
                <Thead>
                  <Tr borderRadius="10px" background="#F2F2F2">
                    <Th
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="700"
                      color="#1A1A1A"
                      textTransform="capitalize"
                      borderRadius="10px 0px 0px 10px"
                    >
                      Next payout
                    </Th>
                    <Th
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="700"
                      color="#1A1A1A"
                      textTransform="capitalize"
                    >
                      Client
                    </Th>
                    <Th
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="700"
                      color="#1A1A1A"
                      textTransform="capitalize"
                    >
                      Claim Amount
                    </Th>
                    <Th
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="700"
                      color="#1A1A1A"
                      textTransform="capitalize"
                    >
                      Tokens in dollars
                    </Th>
                    <Th
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="700"
                      color="#1A1A1A"
                      textTransform="capitalize"
                    >
                      Invested
                    </Th>
                    <Th
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="700"
                      color="#1A1A1A"
                      textTransform="capitalize"
                      borderRadius="0px 10px 10px 0px"
                    >
                      Payout part
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {payoutList?.results &&
                    payoutList?.results?.length > 0 &&
                    payoutList?.results.map((payout) => (
                      <Tr key={payout.id}>
                        <Td
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          <Countdown
                            endDateString={payout.date}
                            paid={payout.paid}
                            openClaimModal={() => {
                              setPayloadId(payout?.id.toString());
                              onOpen();
                            }}
                          />
                        </Td>
                        <Td
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          {payout.client?.name}
                        </Td>
                        <Td
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          {payout.amount}
                        </Td>
                        <Td
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          {payout.investment.token_price
                            ? payout.investment.token_price
                            : '-'}
                        </Td>
                        <Td
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          {payout?.investment?.total_tokens}
                        </Td>
                        <Td
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          {payout.payout_part} of {payout.total_payouts}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt="20px"
              padding={['unset', 'unset', '0px 20px']}
            >
              <Button
                fontFamily="Montserrat"
                fontSize="14px"
                variant="link"
                leftIcon={<ArrowLeftIcon />}
                onClick={handlePrevPage}
                isDisabled={payoutList?.current_page === 1}
              >
                Previous
              </Button>
              <Text
                fontFamily="Montserrat"
                fontSize={['10px', '10px', '14px']}
              >{`Page ${payoutList?.current_page} of ${totalPages}`}</Text>
              <Button
                fontFamily="Montserrat"
                fontSize="14px"
                variant="link"
                rightIcon={<ArrowRightIcon />}
                onClick={handleNextPage}
                isDisabled={payoutList?.current_page === totalPages}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
