import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import defaultImage from '../../assets/Symbol.png';
import sackCoin from '../../assets/sack-coin.png';
import { NonPaginatedPayoutResponse } from '../../interfaces/components';

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const renderPageNumbers = () => {
    const items = [];

    // Helper function to render a page number
    const renderPageNumber = (page: number) => (
      <Box
        key={page}
        background={page === currentPage ? '#0FBF95' : 'white'}
        borderRadius="full"
        padding={'0px 6px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="20px"
          color={page === currentPage ? 'white' : '#8C8C8C'}
          cursor="pointer"
          onClick={() => onPageChange(page)}
        >
          {page}
        </Text>
      </Box>
    );

    // Always render pages 1 and 2
    items.push(renderPageNumber(1));
    if (totalPages > 1) {
      items.push(renderPageNumber(2));
    }

    // Add dots after page 2 if necessary
    if (currentPage > 3 && totalPages > 3) {
      items.push(
        <Text key="dots1" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    // Render current page if it's not 1, 2 or last page
    if (currentPage > 2 && currentPage < totalPages) {
      items.push(renderPageNumber(currentPage));
    }

    // Add dots before last page if necessary
    if (currentPage < totalPages - 1 && totalPages > 3) {
      items.push(
        <Text key="dots2" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    // Render last page if it's different from the first two pages
    if (totalPages > 2) {
      items.push(renderPageNumber(totalPages));
    }

    return items;
  };

  return (
    <HStack spacing="6px" justifyContent={'flex-end'}>
      <IconButton
        aria-label="Previous page"
        icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage <= 1}
        variant="ghost"
        _hover={{
          bg: 'gray.100',
          borderRadius: 'full',
        }}
        transition="all 0.2s"
      />

      {renderPageNumbers()}

      <IconButton
        aria-label="Next page"
        icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage >= totalPages}
        variant="ghost"
        _hover={{
          bg: 'gray.100',
          borderRadius: 'full',
        }}
        transition="all 0.2s"
      />
    </HStack>
  );
};
interface ClaimItemProps {
  logo: string;
  name: string;
  percentage: string;
  amount: string;
}

const ClaimItem: React.FC<ClaimItemProps> = ({
  logo,
  name,
  percentage,
  amount,
}) => {
  return (
    <Tr
      borderRadius="36px"
      bg="#F6F6F6"
      mb="20px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Td border="none" padding="14px" flex="1">
        <Flex alignItems="center" gap="8px">
          <Image src={logo} alt="" boxSize="36px" />
          <Text
            fontSize="13px"
            fontWeight="600"
            lineHeight="normal"
            fontFamily="Montserrat"
            color="#1A1A1A"
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td border="none" padding="14px" flex="1">
        <Flex gap="8px">
          <Flex alignItems="center">
            <Box
              height={['auto', 'auto', 'auto', '20px']}
              padding="0px 4px"
              flexDirection="column"
              alignItems="center"
              borderRadius="6px"
              border="1px solid #0FBF95"
              bg="#0FBF95"
              color="#FFF"
              display="flex"
            >
              <Text
                fontSize="12px"
                fontWeight="700"
                lineHeight="20px"
                fontFamily="Montserrat"
              >
                %
              </Text>
            </Box>
          </Flex>
          <Text>{percentage}</Text>
        </Flex>
      </Td>
      <Td border="none" padding="14px" flex="1" textAlign="right">
        {amount}
      </Td>
    </Tr>
  );
};

const RecentClaimComponent: React.FC<{
  payouts: NonPaginatedPayoutResponse | undefined;
}> = ({ payouts }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 4;

  const totalPages = Math.ceil((payouts?.results.length || 0) / rowsPerPage);

  const paginatedResults = payouts?.results.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="22px"
      flex="1"
      gap="21px"
      bg="white"
      borderRadius="44px"
      boxShadow="0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)"
      border="1px solid #F6F6F6"
    >
      <Flex flexDirection="row" alignItems="center" gap="8px">
        <Image src={sackCoin} alt="" boxSize="28px" />
        <Text
          fontSize="24px"
          fontWeight="600"
          lineHeight="normal"
          fontFamily="Montserrat"
        >
          Recent claims
        </Text>
      </Flex>

      <Table variant="unstyled">
        <Thead mb="16px" display="block" padding={['0px', '6px 14px']}>
          <Tr
            borderRadius="10px"
            border="solid 0.5px #E2E2E2"
            bg="#F6F6F6"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Th
              border="none"
              fontFamily="Montserrat"
              color="#1A1A1A"
              fontSize="13px"
              fontWeight="600"
              lineHeight="20px"
              padding="6px 14px"
              flex="1"
              borderRight="1px solid #E2E2E2"
            >
              Client
            </Th>
            <Th
              border="none"
              fontFamily="Montserrat"
              color="#1A1A1A"
              fontSize="13px"
              fontWeight="600"
              lineHeight="20px"
              padding="6px 14px"
              flex="1"
              borderRight="1px solid #E2E2E2"
              borderLeft="1px solid #E2E2E2"
            >
              Percentage
            </Th>
            <Th
              border="none"
              textAlign="right"
              fontFamily="Montserrat"
              color="#1A1A1A"
              fontSize="13px"
              fontWeight="600"
              lineHeight="20px"
              padding="6px 14px"
              flex="1"
            >
              Amount claimed
            </Th>
          </Tr>
        </Thead>
        <Tbody minHeight={'345px'}>
          {paginatedResults?.map((claim, index) => (
            <ClaimItem
              key={index}
              logo={claim.client.token_img || defaultImage}
              name={claim.client.name}
              percentage={claim.investment.tge_percentage}
              amount={claim.amount}
            />
          ))}
        </Tbody>
        <Box>
          <TablePagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Box>
      </Table>
    </Box>
  );
};

export default RecentClaimComponent;
