import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Deletew from '../../assets/delete white.png';
import Delete from '../../Icons/Delete';
import { DeleteClientModalProps } from '../../interfaces/components';

function DeleteClientModal({
  onClose,
  isOpen,
  clientToDelete,
  onDeleteClick,
}: DeleteClientModalProps) {
  const [_isHovered, _setIsHovered] = useState(false);
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '404px', '404px', '404px']}
        borderRadius="20px"
        padding={['24px', '36px', '36px']}
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalBody
          padding="0px"
          display="flex"
          flexDirection="column"
          gap={5}
          position="relative"
        >
          <CloseIcon
            top="0"
            right="0"
            position="absolute"
            boxSize={4}
            onClick={onClose}
            cursor="pointer"
          />
          <Text fontFamily="Montserrat" fontWeight="700" fontSize="20px">
            Delete client
          </Text>
          <Text fontFamily="Montserrat" fontWeight="500" fontSize="14px">
            Are you sure you want to delete this user? You cant undo this
            action.
          </Text>
          <Box
            display="flex"
            gap="14px"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              width="25%"
              borderRadius="14px"
              border="1px solid #8C8C8C"
              background="#FFF"
              fontFamily="Montserrat"
              fontWeight={600}
              fontSize="14px"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              whiteSpace={['break-spaces', 'break-spaces', 'nowrap']}
              leftIcon={
                _isHovered ? (
                  <img src={Deletew} alt="Hover Icon" width={16} />
                ) : (
                  <Delete boxSize={4} />
                )
              }
              color="#DC3545"
              type="submit"
              width="75%"
              borderRadius="12px"
              background="#FBE9EB"
              fontWeight={600}
              fontFamily="Montserrat"
              fontSize="14px"
              onClick={() => clientToDelete && onDeleteClick()}
              _hover={{
                color: 'white',
                bg: '#DC3545',
              }}
              _active={{
                color: 'white',
                bg: '#BB2030',
              }}
              padding="0px 10px"
              onMouseEnter={() => _setIsHovered(true)}
              onMouseLeave={() => _setIsHovered(false)}
            >
              Permanently delete client
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DeleteClientModal;
