import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getPayouts } from '../../api/InvestorApis/apis';
import topimg from '../../assets/track_changes.png';
import { NonPaginatedPayoutResponse } from '../../interfaces/components';
import CumulativePayoutsChart from './CumulativePayoutsChart';
import PayoutComponent from './PayoutComponent';
import RecentClaimComponent from './RecentClaimComponent';

const UpCommingPayOut: React.FC = () => {
  const toast = useToast();
  // const [payouts, setPayouts] = useState<PayoutResponse>();
  const [nonPaginatedPayouts, setNonPaginatedPayouts] =
    useState<NonPaginatedPayoutResponse>();

  const fetchPayouts = async (paginated:boolean,page: number) => {
    try {
      const res = await getPayouts(paginated,page);
      setNonPaginatedPayouts(res);
  
    } catch (err: any) {
      toast({
        title: 'Something went wrong while fetching payouts',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    fetchPayouts(false,1);
  }, []);

  return (
    <>
      <Box
        marginTop={['14px', '14px', '24px']}
        marginBottom={['28px', '28px', '36px']}
        width={'100%'}
        display={'flex'}
        gap={'10px'}
        paddingLeft={['12px', '16px', '20px']}
        paddingRight={['12px', '16px', '20px']}
      >
        <Flex display={['none', 'flex']}>
          <Text
            fontSize={['20px', '22px', '24px', '36px']}
            fontWeight={700}
            fontFamily="Montserrat"
            textColor={'#1A1A1A'}
          >
            Stay updated with your payouts
          </Text>
          <img src={topimg} alt="" width={'50px'} height={'20px'} />
        </Flex>
        <Flex flexDirection={'column'} display={['flex', 'none']}>
          <Text
            fontSize={['20px', '22px', '24px', '36px']}
            fontWeight={700}
            fontFamily="Montserrat"
            textColor={'#1A1A1A'}
          >
            Stay updated with
          </Text>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={'2px'}
          >
            <Text
              fontSize={['20px', '22px', '24px', '36px']}
              fontWeight={700}
              fontFamily="Montserrat"
              textColor={'#1A1A1A'}
            >
              your payouts
            </Text>
            <img
              src={topimg}
              alt=""
              width={'34px'}
              height={'34px'}
              style={{ transform: 'rotate(15deg)' }}
            />
          </Box>
        </Flex>
      </Box>
      <Flex
        flexDirection={'column'}
        padding={['0px', '0px 20px 0px 20px']}
        gap={'20px'}
      >
        <Flex
          flexDirection={['column', 'column', 'row']}
          gap={'20px'}
          flex={'1'}
        >
          <CumulativePayoutsChart payouts={nonPaginatedPayouts} />
          <RecentClaimComponent payouts={nonPaginatedPayouts} />
        </Flex>
        <PayoutComponent payouts={nonPaginatedPayouts} />
      </Flex>
    </>
  );
};

export default UpCommingPayOut;
