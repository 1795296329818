import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import mail_off from '../../assets/mail_off.png';

interface RequestFailedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function RequestFailedModal({
  isOpen,
  onClose,
}: RequestFailedModalProps) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '404px', '500px', '500px']}
        borderRadius="20px"
        padding="22px"
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalBody padding="0px">
          <Box gap={'16px'} display={'flex'} flexDirection={'column'}>
            <Box
              display={'flex'}
              flexDir={'column'}
              justifyContent={'center'}
              gap={'12px'}
            >
              <Text
                color="#1A1A1A"
                fontFamily="Montserrat"
                fontSize={['16px', '28px', '20px']}
                fontStyle="normal"
                fontWeight="700"
                lineHeight="normal"
                alignSelf={'center'}
              >
                Reset request failed
              </Text>
              <Text
                color="#8C8C8C"
                fontFamily="Montserrat"
                fontSize={['10px', '14px', '14px']}
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                whiteSpace={'nowrap'}
              >
                Please double check your email and try again
              </Text>
            </Box>
            <Box justifyContent={'center'} display={'flex'}>
              <Box
                display="flex"
                padding="20px"
                alignItems="center"
                gap="10px"
                borderRadius="64px"
                background="var(--Color-Yellow-50, #FFF5E5)"
                w={'fit-content'}
                alignSelf={'center'}
              >
                <Image src={mail_off} alt="Image" width="54px" height="54px" />
              </Box>
            </Box>

            <Box display={'flex'} justifyContent={'center'}>
              <Text
                color="#8C8C8C"
                fontFamily="Montserrat"
                fontSize={['10px', '14px', '14px']}
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                whiteSpace={'nowrap'}
                alignSelf={'center'}
              >
                Click here to{' '}
                <span style={{ color: '#FA9B00', cursor: 'pointer' }}>
                  Try agin
                </span>
              </Text>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
