import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Flex,
  Image,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import defaultImage from '../../assets/Symbol.png';
import schedule from '../../assets/schedule.png';
import { NonPaginatedPayoutResponse } from '../../interfaces/components';
import { formatDateForInput, hasDatePassed } from '../../utils/commonFunctions';
import ClaimTokenModal from '../claimToken/ClaimTokenModal';
import Countdown from './Countdown';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={i}
            onClick={() => handlePageChange(i)}
            bg={currentPage === i ? '#0FBF95' : 'white'}
            color={currentPage === i ? 'white' : 'black'}
            _hover={{
              bg: currentPage === i ? '#0FBF95' : 'white',
            }}
            mx="1"
          >
            {i}
          </Button>,
        );
      }
    } else {
      [1, 2].forEach((i) => {
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={i}
            onClick={() => handlePageChange(i)}
            bg={currentPage === i ? '#0FBF95' : 'white'}
            color={currentPage === i ? 'white' : 'black'}
            _hover={{
              bg: currentPage === i ? '#0FBF95' : 'white',
            }}
            mx="1"
          >
            {i}
          </Button>,
        );
      });

      if (currentPage > 2 && currentPage < totalPages) {
        pageNumbers.push(<Text key="ellipsis1">...</Text>);
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={currentPage}
            onClick={() => handlePageChange(currentPage)}
            bg="#0FBF95"
            color="white"
            _hover={{
              bg: '#0FBF95',
            }}
            mx="1"
          >
            {currentPage}
          </Button>,
        );
      }

      if (currentPage < totalPages - 1) {
        pageNumbers.push(<Text key="ellipsis2">...</Text>);
      }

      pageNumbers.push(
        <Button
          borderRadius={'100px'}
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          bg={currentPage === totalPages ? '#0FBF95' : 'white'}
          color={currentPage === totalPages ? 'white' : 'black'}
          _hover={{
            bg: currentPage === totalPages ? '#0FBF95' : 'white',
          }}
          mx="1"
        >
          {totalPages}
        </Button>,
      );
    }
    return pageNumbers;
  };

  return (
    <Flex
      alignItems="center"
      justifyContent={['flex-end', 'flex-end', 'center']}
    >
      <Box
        padding={['10px', '12px', '14px', '16px']}
        bg={'#FFF'}
        border={'1px solid #EFEFEF'}
        borderRadius={'44px'}
        display={'flex'}
      >
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          bg="white"
          _hover={{
            background: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        >
          &lt;
        </Button>
        {renderPageNumbers()}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          bg="white"
          _hover={{
            background: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        >
          &gt;
        </Button>
      </Box>
    </Flex>
  );
};
const PayoutComponent: React.FC<{
  payouts: NonPaginatedPayoutResponse | undefined;
}> = ({ payouts }) => {
  const [payloadId, setPayloadId] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 6;
  const totalPages = Math.ceil((payouts?.results.length ?? 0) / itemsPerPage);

  const payoutList = payouts?.results.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const openRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPayloadId('');
    setIsModalOpen(false);
  };

  return (
    <>
      <ClaimTokenModal
        isOpen={isModalOpen}
        onClose={closeModal}
        payoutId={payloadId}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'22px'}
        flex={'1'}
        gap={['24px', '24px', '24px', '21px']}
        background={'rgba(255, 255, 255, 1)'}
        borderRadius={'44px'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        border={'1px solid #F6F6F6'}
      >
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Text
            fontSize={'24px'}
            fontWeight={'600'}
            lineHeight={'normal'}
            fontFamily="Montserrat"
            color={'#1A1A1A'}
          >
            Payouts
          </Text>
          {/* <Box
            display={'flex'}
            flexDirection={'row'}
            padding={'11px 14px'}
            gap={'4px'}
            borderRadius={'21px'}
            border={'1px solid #E2E2E2'}
          >
            <Img src={pen} alt="" height={'18px'} width={'18px'} />
            <Text
              fontSize={'16px'}
              fontWeight={'500'}
              color={'#1A1A1A'}
              lineHeight={'normal'}
              fontFamily="Montserrat"
            >
              Edit investment
            </Text>
          </Box> */}
        </Flex>
        <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Client
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Claim Amount
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Dollar Value
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Invested
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Payout Part
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'right'}
              >
                Next payout
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {payoutList?.map((data, index) => (
              <Tr
                key={data.id}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 6 === 0 ? '0px' : '20px'}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Td border="none" padding="14px" flex="1">
                  <Flex alignItems={'center'} gap={'8px'}>
                    {/* <Img
                      src={data.client.token_img}
                      alt=""
                      height={'36px'}
                      width={'36px'}
                    /> */}
                    <Image
                      src={data.client.token_img || defaultImage}
                      alt={data.client.name}
                      height={'36px'}
                      width={'36px'}
                      fallbackSrc={defaultImage}
                      onError={(
                        e: React.SyntheticEvent<HTMLImageElement, Event>,
                      ) => {
                        const target = e.target as HTMLImageElement;
                        target.src = defaultImage;
                      }}
                    />
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.client.name}
                    </Text>
                  </Flex>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  {data.amount}
                </Td>

                <Td
                  border="none"
                  padding="14px"
                  flex="1"
                  gap={'8px'}
                  display={'flex'}
                  flexDirection={'row'}
                >
                  <Box
                    width={'20px'}
                    height={'20px'}
                    padding={'0px 4px'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    borderRadius={'6px'}
                    border={'1px solid #0FBF95'}
                    background={'#0FBF95'}
                    color={'#FFF'}
                    display={'flex'}
                  >
                    <Text
                      fontSize={'12px'}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                    >
                      $
                    </Text>
                  </Box>

                  {(
                    parseFloat(data.amount) * (data.client.token_price ?? 0)
                  ).toFixed(2)}
                </Td>

                <Td border="none" padding="14px" flex="1">
                  <Text>${data.investment.total_amount}</Text>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  <Flex flexDirection={'column'}>
                    <Text
                      color={'#8C8C8C'}
                      fontSize={'12px'}
                      fontWeight={'600'}
                      lineHeight={'20px'}
                    >
                      {`${data.payout_part} of ${data.total_payouts}`}
                    </Text>
                    <Progress
                      width={'40%'}
                      value={(data.payout_part / data.total_payouts) * 100}
                      height={'6px'}
                      borderRadius={'100px'}
                      background="rgba(226, 226, 226, 1)"
                      sx={{
                        '& > div': {
                          background: '#0FBF95',
                        },
                      }}
                    />
                  </Flex>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  {!hasDatePassed(data.date) ? (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      padding={'10px 18px'}
                      justifyContent={'center'}
                      alignItems={'flex-start'}
                      gap={'5px'}
                      borderRadius={'22px'}
                      border={'1px solid #E2E2E2'}
                      background={'#FFF'}
                      width={'100%'}
                    >
                      <Flex alignItems={'center'} gap={'7px'}>
                        <Countdown endDateString={data.date}></Countdown>
                      </Flex>
                      <Progress
                        width={'100%'}
                        value={0}
                        height={'6px'}
                        borderRadius={'100px'}
                        background="rgba(226, 226, 226, 1)"
                        sx={{
                          '& > div': {
                            background: '#0FBF95',
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <Button
                        onClick={() => {
                          setPayloadId(data.id.toString());
                          openModal();
                        }}
                        disabled={data.paid}
                        display={'flex'}
                        flexDirection={'row'}
                        padding={'4px 8px'}
                        borderRadius={'12px'}
                        border={'1px solid #E2E2E2'}
                        height={'28px'}
                        gap={'10px'}
                        background={'#1A1A1A'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Text
                          fontSize={'13px'}
                          fontWeight={'600'}
                          color={'#FFF'}
                          lineHeight={'20px'}
                          fontFamily="Montserrat"
                        >
                          {data.paid ? 'Tokens Claimed' : 'Claim Tokens'}
                        </Text>
                      </Button>
                    </Box>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Table variant="unstyled" display={['table', 'table', 'table', 'none']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                position="relative"
              >
                Client
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '15%',
                    bottom: '15%',
                    width: '1px',
                    background: '#E2E2E2',
                  }}
                />
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'end'}
              >
                Claim Amount
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {payoutList?.map((data, index) => (
              <Tr
                key={index}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 6 === 0 ? '0px' : '20px'}
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="20px 20px 14px 20px"
                  onClick={() => openRow(index)}
                  cursor="pointer"
                  borderBottom={'1px solid #E2E2E2 '}
                >
                  <Flex alignItems={'center'} gap={'8px'}>
                    <Image src={''} alt="" height={'36px'} width={'36px'} />
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.client.name}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.amount}
                    </Text>
                    <ChevronDownIcon
                      color="#8C8C8C"
                      boxSize={6}
                      transform={
                        expandedRowIndex === index ? 'rotate(180deg)' : 'unset'
                      }
                      transition="0.2s all"
                    />
                  </Flex>
                </Box>

                <Collapse in={expandedRowIndex === index} animateOpacity>
                  <Box padding="0px 20px 14px 20px">
                    <Flex justifyContent="space-between" mb="10px">
                      <Text color="#8C8C8C" fontSize="13px" fontWeight="500">
                        Dollar Value
                      </Text>
                      <Text>
                        {parseFloat(data.amount) *
                          (data.client.token_price ?? 0)}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text color="#8C8C8C" fontSize="13px" fontWeight="500">
                        Invested
                      </Text>
                      <Flex gap={'8px'} alignItems={'center'}>
                        <Box
                          width={'20px'}
                          height={'20px'}
                          padding={'0px 4px'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          borderRadius={'6px'}
                          border={'1px solid #0FBF95'}
                          background={'#0FBF95'}
                          color={'#FFF'}
                          display={'flex'}
                        >
                          <Text
                            fontSize={'12px'}
                            fontWeight={'700'}
                            lineHeight={'20px'}
                            fontFamily="Montserrat"
                          >
                            %
                          </Text>
                        </Box>
                        <Text>{data.investment.tge_percentage}</Text>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text color="#8C8C8C" fontSize="13px" fontWeight="500">
                        Payout Part
                      </Text>
                      <Flex flexDirection={'column'} alignItems="flex-end">
                        <Text
                          color={'#8C8C8C'}
                          fontSize={'12px'}
                          fontWeight={'600'}
                          lineHeight={'20px'}
                        >
                          {`${data.payout_part} of ${data.total_payouts}`}
                        </Text>
                        <Progress
                          width={'100%'}
                          value={(data.payout_part / data.total_payouts) * 100}
                          height={'6px'}
                          borderRadius={'100px'}
                          background="rgba(226, 226, 226, 1)"
                          sx={{
                            '& > div': {
                              background: '#0FBF95',
                            },
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                </Collapse>

                <Box
                  borderTop={
                    expandedRowIndex === index ? '1px solid #E2E2E2' : 'none'
                  }
                  padding="14px 20px 20px 20px"
                >
                  {!hasDatePassed(data.date) ? (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      padding={'10px 18px'}
                      justifyContent={'center'}
                      alignItems={'flex-start'}
                      gap={'5px'}
                      borderRadius={'22px'}
                      border={'1px solid #E2E2E2'}
                      background={'#FFF'}
                      width={'100%'}
                    >
                      <Flex alignItems={'center'} gap={'7px'}>
                        <Image src={schedule} width={'20px'} height={'20px'} />
                        <Text>{formatDateForInput(data.date)}</Text>
                      </Flex>
                      <Progress
                        width={'100%'}
                        value={0}
                        height={'6px'}
                        borderRadius={'100px'}
                        background="rgba(226, 226, 226, 1)"
                        sx={{
                          '& > div': {
                            background: '#0FBF95',
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <Button
                        onClick={openModal}
                        display={'flex'}
                        flexDirection={'row'}
                        padding={'4px 8px'}
                        borderRadius={'12px'}
                        border={'1px solid #E2E2E2'}
                        height={'28px'}
                        gap={'10px'}
                        background={'#1A1A1A'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Text
                          fontSize={'13px'}
                          fontWeight={'600'}
                          color={'#FFF'}
                          lineHeight={'20px'}
                          fontFamily="Montserrat"
                        >
                          Claim tokens
                        </Text>
                      </Button>
                    </Box>
                  )}
                </Box>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </>
  );
};

export default PayoutComponent;
