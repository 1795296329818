import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {
    ClientResponse,
    ClientResultsArray,
    InvestmentPayload,
    InvestmentResultsArray,
    PayoutResponse,
    UserResponse,
    UserLogInResponse,
} from './interfaces/components';

export interface AppStoreState {
    loginApiResponse: UserLogInResponse | null;
    loading: boolean;
    cTgId: string;
    currentUser: UserResponse | null;
    payoutList: PayoutResponse | null;
    clientsList: ClientResponse | null;
    investmentsList: InvestmentPayload | null;
    clientToEdit: ClientResultsArray | null;
    investmentToEdit: InvestmentResultsArray | null;

    setCurrentUser: (currentUser: UserResponse | null) => void;
    setInvestmentToEdit: (
        investmentToEdit: InvestmentResultsArray | null,
    ) => void;
    setClientToEdit: (payoutList: ClientResultsArray | null) => void;
    setInvestmentsList: (payoutList: InvestmentPayload | null) => void;
    setClientsList: (payoutList: ClientResponse | null) => void;
    setLoading: (loading: boolean) => void;
    setLoginApiResponse: (loginResponse: UserLogInResponse | null) => void;
    setPayoutList: (payoutList: PayoutResponse) => void;
    setCTgId: (tgId: string) => void;
}

const LOCAL_STORAGE_KEY = 'loginApiResponse';

const getInitialLoginApiResponse = (): UserLogInResponse | null => {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedData ? JSON.parse(storedData) : null;
};

export const useAppStore = create<AppStoreState>()(
    devtools((set) => ({
        loginApiResponse: getInitialLoginApiResponse(),
        currentUser: null,
        cTgId: '',
        loading: false,
        payoutList: null,
        clientsList: null,
        investmentsList: null,
        clientToEdit: null,
        investmentToEdit: null,

        setCurrentUser: (currentUser) => set(() => ({currentUser: currentUser})),
        setInvestmentToEdit: (investmentToEdit) =>
            set(() => ({investmentToEdit: investmentToEdit})),
        setClientToEdit: (clientToEdit) =>
            set(() => ({clientToEdit: clientToEdit})),
        setInvestmentsList: (investmentsList) =>
            set(() => ({investmentsList: investmentsList})),
        setClientsList: (clientsList) => set(() => ({clientsList: clientsList})),
        setPayoutList: (payoutList) => set(() => ({payoutList: payoutList})),
        setLoading: (loading) => set(() => ({loading: loading})),
        setCTgId: (tgId) => set(() => ({cTgId: tgId})),
        setLoginApiResponse: (loginApiResponse) => {
            // Save to local storage
            if (loginApiResponse) {
                localStorage.setItem(
                    LOCAL_STORAGE_KEY,
                    JSON.stringify(loginApiResponse),
                );
            } else {
                localStorage.removeItem(LOCAL_STORAGE_KEY);
            }
            set(() => ({loginApiResponse: loginApiResponse}));
        },
    })),
);
