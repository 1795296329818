import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import MailSendModal from './MailSendModal';
import { sendForgetPasswordEmail } from '../../api/InvestorApis/client';

interface ForgetModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ForgetModal({ isOpen, onClose }: ForgetModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email,setEmail] = useState<string>('')
  console.log("email = ",email)
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '404px', '500px', '500px']}
        borderRadius="20px"
        padding="22px"
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalBody padding="0px">
          <Box>
            <Box display={'flex'} flexDir={'column'} justifyContent={'center'}>
              <Text
                color="#1A1A1A"
                fontFamily="Montserrat"
                fontSize={['16px', '28px', '20px']}
                fontStyle="normal"
                fontWeight="700"
                lineHeight="normal"
                alignSelf={'center'}
              >
                Reset your password
              </Text>
            </Box>

            <form style={{ marginTop: '24px' }}>
              <VStack spacing={1} align="stretch">
                <FormControl>
                  <FormLabel
                    htmlFor="email"
                    color="#8C8C8C"
                    fontSize="12px"
                    fontFamily="Montserrat"
                    
                  >
                    A password reset link will be sent to your email address
                  </FormLabel>
                  <Input
                    borderRadius="12px"
                    variant="filled"
                    id="email"
                    type="email"
                    placeholder="Email address"
                    onChange={(e)=>{
                      e.preventDefault()
                      setEmail(e.target.value)
                    }}
                  />
                </FormControl>

                <Box display="flex" gap="14px" justifyContent={'center'}>
                  <Button
                    color="white"
                    whiteSpace="break-spaces"
                    type="submit"
                    marginTop="24px"
                    width="100%"
                    borderRadius="14px"
                    background="#0FBF95"
                    fontWeight={600}
                    fontSize="14px"
                    fontFamily="Montserrat"
                    onClick={async (e) => {
                      e.preventDefault()
                      await sendForgetPasswordEmail(email);
                      setIsModalOpen(true)

                    }}
                    _hover={{
                      color: 'white',
                      bg: '#0FBF75',
                    }}
                  >
                    Reset password
                  </Button>
                  <MailSendModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  />
                </Box>
              </VStack>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
