import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Liquidate } from '../../interfaces/components';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={i}
            onClick={() => handlePageChange(i)}
            bg={currentPage === i ? '#0FBF95' : 'white'}
            color={currentPage === i ? 'white' : 'black'}
            _hover={{
              bg: currentPage === i ? '#0FBF95' : 'white',
            }}
            mx="1"
          >
            {i}
          </Button>,
        );
      }
    } else {
      [1, 2].forEach((i) => {
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={i}
            onClick={() => handlePageChange(i)}
            bg={currentPage === i ? '#0FBF95' : 'white'}
            color={currentPage === i ? 'white' : 'black'}
            _hover={{
              bg: currentPage === i ? '#0FBF95' : 'white',
            }}
            mx="1"
          >
            {i}
          </Button>,
        );
      });

      if (currentPage > 2 && currentPage < totalPages) {
        pageNumbers.push(<Text key="ellipsis1">...</Text>);
        pageNumbers.push(
          <Button
            borderRadius={'100px'}
            key={currentPage}
            onClick={() => handlePageChange(currentPage)}
            bg="#0FBF95"
            color="white"
            _hover={{
              bg: '#0FBF95',
            }}
            mx="1"
          >
            {currentPage}
          </Button>,
        );
      }

      if (currentPage < totalPages - 1) {
        pageNumbers.push(<Text key="ellipsis2">...</Text>);
      }

      pageNumbers.push(
        <Button
          borderRadius={'100px'}
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          bg={currentPage === totalPages ? '#0FBF95' : 'white'}
          color={currentPage === totalPages ? 'white' : 'black'}
          _hover={{
            bg: currentPage === totalPages ? '#0FBF95' : 'white',
          }}
          mx="1"
        >
          {totalPages}
        </Button>,
      );
    }
    return pageNumbers;
  };

  return (
    <Flex
      alignItems="center"
      justifyContent={['flex-end', 'flex-end', 'center']}
      mt={4}
    >
      <Box
        padding={['6px', '6px', '8px', '8px']}
        bg={'#FFF'}
        border={'1px solid #EFEFEF'}
        borderRadius={'44px'}
        display={'flex'}
      >
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          bg="white"
          _hover={{
            background: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        >
          &lt;
        </Button>
        {renderPageNumbers()}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          bg="white"
          _hover={{
            background: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        >
          &gt;
        </Button>
      </Box>
    </Flex>
  );
};

const LiquidationTable: React.FC<{ liquidateData: Liquidate | undefined,fetchLiquidateData:(page:number)=>Promise<void> }> = ({
  liquidateData,
  fetchLiquidateData
}) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<
    number | undefined
  >();
  const onPageChange = (page: number) => {
    setCurrentPage(page);
    fetchLiquidateData(page);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const openRow = (index: number) => {
    if (index === expandedRowIndex) {
      setExpandedRowIndex(undefined);
    } else {
      setExpandedRowIndex(index);
    }
  };

  const totalPages = liquidateData && liquidateData?.total_pages ? liquidateData?.total_pages : 0

 
  

  return (
    <>
      <Text
        color={'#1A1A1A'}
        fontSize={['18px', '18px', '18px', '24px']}
        fontWeight={600}
        fontFamily="Montserrat"
        padding={[
          '0px 16px 0px 16px',
          '0px 16px 0px 16px',
          '0px 16px 0px 16px',
          'none',
          'none',
        ]}
      >
        Liquidation tracker
      </Text>
      <TableContainer marginTop="10px">
        <Table variant="simple" display={['none', 'none', 'none', 'table']}>
          <Thead>
            <Tr borderRadius="10px" background="#F2F2F2">
              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
                borderRadius="10px 0px 0px 10px"
              >
                Sold for (USD)
              </Th>
              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
              >
                Claim Value (USD)
              </Th>
              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
              >
                Profit (USD)
              </Th>
              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
              >
                Client
              </Th>
              <Th
                fontFamily="Montserrat"
                fontSize="13px"
                fontWeight="700"
                color="#1A1A1A"
                textTransform="capitalize"
                borderRadius="0px 10px 10px 0px"
              >
                Token Amount
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {liquidateData?.results?.map((item) => (
              <Tr key={item.id}>
                <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                  ${item.sell_value}
                </Td>
                <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                  ${item.claim_value}
                </Td>
                <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                  ${parseFloat(item.sell_value) - parseFloat(item.claim_value)}
                </Td>
                <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                  {item.client_name}
                </Td>
                <Td fontFamily="Montserrat" fontSize="13px" fontWeight="500">
                  {item.token_amount}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Table variant="simple" display={['table', 'table', 'table', 'none']}>
          <Thead>
            <Tr
              borderRadius="10px"
              borderBottom="10px solid white"
              background="#F2F2F2"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                padding="0.75rem 1.5rem"
              >
                <Text
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="700"
                  color="#1A1A1A"
                  textTransform="capitalize"
                >
                  Client
                </Text>
                <Text
                  fontFamily="Montserrat"
                  fontSize="13px"
                  fontWeight="700"
                  color="#1A1A1A"
                  textTransform="capitalize"
                >
                  Profit (USD)
                </Text>
              </Box>
            </Tr>
          </Thead>
          <Tbody mt="10px">
            {liquidateData?.results?.map((item, index) => (
              <Tr
                key={item.id}
                borderBottom={
                  expandedRowIndex !== index
                    ? '1px solid #E2E2E2'
                    : '1px solid white'
                }
              >
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  gap={expandedRowIndex === index ? 4 : 0}
                  padding="16px 12px"
                  borderRadius="10px"
                  bg={expandedRowIndex === index ? '#F2F2F2' : 'white'}
                  borderBottom={
                    expandedRowIndex === index
                      ? '1px solid #E2E2E2'
                      : '1px solid white'
                  }
                  transition="0.2s all"
                  cursor="pointer"
                  onClick={() => openRow(index)}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Text
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="500"
                    >
                      {item.client_name}
                    </Text>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Text
                        fontFamily="Montserrat"
                        fontSize="13px"
                        fontWeight="500"
                        textAlign="end"
                      >
                        $
                        {parseFloat(item.sell_value) -
                          parseFloat(item.claim_value)}
                      </Text>
                      <ChevronDownIcon
                        color="#8C8C8C"
                        boxSize={6}
                        transform={
                          expandedRowIndex === index
                            ? 'rotate(180deg)'
                            : 'unset'
                        }
                        transition="0.2s all"
                      />
                    </Box>
                  </Box>

                  <Collapse in={expandedRowIndex === index} animateOpacity>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      alignItems="center"
                      width="100%"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Text
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          color="#8C8C8C"
                        >
                          Sold for (USD)
                        </Text>
                        <Text
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          color="#8C8C8C"
                        >
                          ${item.sell_value}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Text
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          color="#8C8C8C"
                        >
                          Claim Value (USD)
                        </Text>
                        <Text
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          color="#8C8C8C"
                        >
                          ${item.claim_value}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                      >
                        <Text
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          color="#8C8C8C"
                        >
                          Token Amount
                        </Text>
                        <Text
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          color="#8C8C8C"
                        >
                          {item.token_amount}
                        </Text>
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default LiquidationTable;
