import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { userSignUp } from '../../api/InvestorApis/apis';
import { UserSignUpPayload } from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';

export default function Signup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      wallet_address: '',
      email: '',
      password: '',
      first_name: '',
      last_name: '',
    },
  });
  console.log('erress', errors);
  const navigate = useNavigate();
  const toast = useToast();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);

  const handleFormSubmit = async (data: UserSignUpPayload) => {
    try {
      setLoading(true);
      const res = await userSignUp(data);
      if (res) {
        // setLoginApiResponse(res as any);
        setLoading(false);
        toast({
          title:
            'User registration successful.Login to continue using the app.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/login');
        reset();
      }
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while user registration.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('Error While Fetch All Users ', error);
    }
  };
  return (
    <>
      {loading ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          boxShadow={['none', 'none', '0px 0px 10px 0px rgba(0, 0, 0, 0.15)']}
          padding={['20px 20px', '22px 22px']}
          borderRadius="36px"
          width={['90vw', '90vw', '500px']}
          border="1px solid #E2E2E2"
          background={'#fff'}
        >
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <VStack spacing={4}>
              <Text fontSize="24px" fontFamily="Montserrat" fontWeight="700">
                Sign up
              </Text>
              {/* First Name */}
              <FormControl isRequired>
                <FormLabel
                  htmlFor="first_name"
                  color="#8C8C8C"
                  fontSize="12px"
                  fontFamily="Montserrat"
                >
                  First Name
                </FormLabel>
                <Input
                  borderRadius="12px"
                  variant="filled"
                  placeholder="Enter your first name"
                  id="first_name"
                  type="text"
                  {...register('first_name', {
                    required: 'First name is required',
                  })}
                />
                {/* <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage> */}
              </FormControl>
              {/* Last Name */}
              <FormControl isRequired>
                <FormLabel
                  htmlFor="last_name"
                  color="#8C8C8C"
                  fontSize="12px"
                  fontFamily="Montserrat"
                >
                  Last Name
                </FormLabel>
                <Input
                  borderRadius="12px"
                  variant="filled"
                  placeholder="Enter your last name"
                  id="last_name"
                  type="text"
                  {...register('last_name', {
                    required: 'Last name is required',
                  })}
                />
                {/* <FormErrorMessage>{errors.last_name && errors.last_name.message}</FormErrorMessage> */}
              </FormControl>
              {/* Email */}
              <FormControl isRequired>
                <FormLabel
                  htmlFor="email"
                  color="#8C8C8C"
                  fontSize="12px"
                  fontFamily="Montserrat"
                >
                  Email
                </FormLabel>
                <Input
                  borderRadius="12px"
                  variant="filled"
                  placeholder="Enter your email"
                  id="email"
                  type="email"
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Invalid email address',
                    },
                  })}
                />
                {/* <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage> */}
              </FormControl>
              {/* Wallet Address */}
              {/* <FormControl isRequired>
                <FormLabel
                  htmlFor="wallet_address"
                  color="#8C8C8C"
                  fontSize="12px"
                  fontFamily="Montserrat"
                >
                  Wallet Address
                </FormLabel>
                <Input
                  borderRadius="12px"
                  variant="filled"
                  placeholder="Enter your wallet address"
                  id="wallet_address"
                  type="text"
                  {...register('wallet_address', {
                    required: 'Wallet address is required',
                    pattern: {
                      value: /^0x[a-fA-F0-9]{40}$/,
                      message: 'Invalid Ethereum address',
                    },
                  })}
                />
                {/* <FormErrorMessage>{errors.wallet_address && errors.wallet_address.message}</FormErrorMessage> */}
              {/* </FormControl> */}
              {/* Password */}
              <FormControl isRequired>
                <FormLabel
                  htmlFor="password"
                  color="#8C8C8C"
                  fontSize="12px"
                  fontFamily="Montserrat"
                >
                  Password
                </FormLabel>
                <Input
                  borderRadius="12px"
                  id="password"
                  variant="filled"
                  placeholder="Enter your password"
                  type="password"
                  {...register('password', {
                    required: 'Password is required',
                    minLength: {
                      value: 6,
                      message: 'Password must be at least 6 characters',
                    },
                  })}
                />
                {/* <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage> */}
              </FormControl>
              {/* Submit Button */}

              <Text
                variant="link"
                color="#8C8C8C"
                fontSize="12px"
                fontFamily="Montserrat"
              >
                Already have an account?&nbsp;
                <span
                  style={{
                    color: '#0FBF95',
                    fontWeight: '700',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate('/')}
                >
                  Login
                </span>
              </Text>

              <Button
                bg="#0FBF95"
                color="white"
                type="submit"
                width="full"
                fontFamily="Montserrat"
                borderRadius="14px"
                fontSize="14px"
                _hover={{
                  color: 'white',
                  bg: '#0FBF75',
                }}
              >
                Sign up
              </Button>
            </VStack>
          </form>
        </Box>
      )}
    </>
  );
}
