import { AddIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteClientById, getClients } from '../../api/InvestorApis/client';
import ClientsLogo from '../../Icons/ClientsLogo';
import Delete from '../../Icons/Delete';
import { ClientResultsArray } from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';
import DeleteClientModal from './DeleteClientModal';
import ManageClientModal from './ManageClientModal';

export default function Client() {
  const navigate = useNavigate();
  const toast = useToast();
  const clientsList = useAppStore((state: AppStoreState) => state.clientsList);
  const setClientsList = useAppStore(
    (state: AppStoreState) => state.setClientsList,
  );
  const itemsPerPage =
    clientsList &&
    clientsList?.results?.length > 0 &&
    clientsList?.results?.length;
  const totalPages = itemsPerPage && clientsList && clientsList?.total_pages;
  const [clientToDelete, setClientToDelete] = useState<
    ClientResultsArray | any
  >(null);
  const [clientToEdit, setClientToEdit] = useState<ClientResultsArray | any>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: manageModalOpen,
    onOpen: openManageModal,
    onClose: closeManageModal,
  } = useDisclosure();

  const fetchClients = async (page: number) => {
    try {
      setLoading(true);
      const res = await getClients(page);
      if (res) {
        setClientsList(res);
        setLoading(false);
        onClose();
        res.current_page < res.total_pages &&
          setCurrentPage(res.current_page + 1);
      }
    } catch (err: any) {
      setLoading(false);
      onClose();
      toast({
        title: 'Something went wrong while fetching clients',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchClients(currentPage);
  }, []);

  const onEditClick = (client: ClientResultsArray) => {
    setClientToEdit(client);
    openManageModal();
  };

  const onDeleteClick = async (client: ClientResultsArray) => {
    try {
      setLoading(true);
      const res = await deleteClientById(client?.id);
      if (res) {
        toast({
          title: 'Client deleted successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      }
      fetchClients(currentPage);
    } catch (err: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while deleting client',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      console.log('Error While Fetch All Users ', err);
    }
  };

  const handlePrevPage = () => {
    clientsList?.current_page && fetchClients(currentPage - 1);
  };

  const handleNextPage = () => {
    console.log('prev');
    clientsList?.current_page && fetchClients(currentPage);
  };

  return (
    <>
      <ManageClientModal
        isOpen={manageModalOpen}
        onClose={() => {
          closeManageModal();
        }}
        onAdded={() => {
          closeManageModal();
          fetchClients(currentPage);
        }}
        clientToEdit={clientToEdit}
      />
      <DeleteClientModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        clientToDelete={clientToDelete}
        onDeleteClick={() => {
          onClose();
          onDeleteClick(clientToDelete);
        }}
      />

      {loading ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Box>
      ) : (
        <Box width="100%" height="100%">
          <Box
            pb="30px"
            display="flex"
            flexDirection="column"
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={3}
            >
              <Text
                fontFamily="Montserrat"
                fontWeight="700"
                fontSize={['16px', '18px', '22px', '36px', '36px']}
                color="#1A1A1A"
                marginLeft={['20px', '20px', 'unset']}
              >
                Organize your investments
              </Text>
              <ClientsLogo />
            </Box>
          </Box>

          {/* start wokzone */}

          <Box
            width={['100%', '100%', '580px', '720px', '720px']}
            bg="white"
            borderRadius="20px"
            padding="16px"
            margin="0 auto"
            border="1px solid #E2E2E2"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                fontFamily="Montserrat"
                fontSize={['14px', '18px', '24px']}
                fontWeight="600"
                color="#1A1A1A"
              >
                Clients
              </Text>

              <Button
                leftIcon={<AddIcon color="white" />}
                color="white"
                borderRadius="12px"
                background="#0FBF95"
                fontSize=" 14px"
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="normal"
                onClick={() => {
                  setClientToEdit(null);
                  openManageModal();
                }}
                _hover={{
                  color: 'white',
                  bg: '#0FBF75',
                }}
              >
                Add Client
              </Button>
            </Box>

            <TableContainer mt="20px">
              <Table variant="simple">
                <Thead>
                  <Tr background="#F2F2F2">
                    <Th
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="700"
                      color="#1A1A1A"
                      textTransform="capitalize"
                      borderRadius="10px 0px 0px 10px"
                    >
                      Client
                    </Th>
                    <Th
                      fontFamily="Montserrat"
                      fontSize="13px"
                      fontWeight="700"
                      color="#1A1A1A"
                      textAlign="end"
                      textTransform="capitalize"
                      borderRadius="0px 10px 10px 0px"
                    >
                      Actions
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {clientsList?.results &&
                    clientsList?.results?.length > 0 &&
                    clientsList.results?.map((client) => (
                      <Tr key={client.id}>
                        <Td
                          fontFamily="Montserrat"
                          fontSize="13px"
                          fontWeight="500"
                          onClick={() => navigate(`/SingleClient/${client.id}`)}
                          cursor="pointer"
                        >
                          {client.name}
                        </Td>
                        <Td
                          display="flex"
                          alignItems="center"
                          gap="10px"
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="outline"
                            borderRadius="12px"
                            fontSize="13px"
                            fontFamily="Montserrat"
                            fontWeight="500"
                            onClick={() => onEditClick(client)}
                            border=" 1px solid var(--Color-Neutral-500, #8C8C8C)"
                            background=" var(--Color-Neutral-50, #FFF)"
                            width="27px"
                            height="28px"
                            padding="4px 8px"
                            color="#1A1A1A"
                          >
                            Edit
                          </Button>
                          <Delete
                            boxSize={6}
                            cursor="pointer"
                            onClick={() => {
                              setClientToDelete(client);
                              onOpen();
                            }}
                          />
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt="20px"
              padding={['unset', 'unset', '0px 20px']}
            >
              <Button
                fontFamily="Montserrat"
                fontSize="14px"
                variant="link"
                leftIcon={<ArrowLeftIcon />}
                onClick={handlePrevPage}
                isDisabled={clientsList?.current_page === 1}
              >
                Previous
              </Button>
              <Text
                fontFamily="Montserrat"
                fontSize={['10px', '10px', '14px']}
              >{`Page ${clientsList?.current_page} of ${totalPages}`}</Text>
              <Button
                fontFamily="Montserrat"
                fontSize="14px"
                variant="link"
                rightIcon={<ArrowRightIcon />}
                onClick={handleNextPage}
                isDisabled={clientsList?.current_page === totalPages}
              >
                Next
              </Button>
            </Box>
          </Box>

          <Box display="none" justifyContent="flex-end">
            <Button
              color="white"
              borderRadius="12px"
              background="#5772FF"
              fontSize=" 14px"
              fontStyle="normal"
              fontWeight=" 500"
              lineHeight="normal"
              h={'37px'}
              onClick={() => {
                setClientToEdit(null);
                navigate('/client/add');
              }}
              _hover={{
                color: 'white',
                bg: '#0FBF75',
              }}
            >
              Add Client
            </Button>
          </Box>
          <TableContainer
            display="none"
            marginTop="10px"
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
            padding="40px 40px"
            borderRadius="12px"
          >
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {clientsList?.results &&
                  clientsList?.results?.length > 0 &&
                  clientsList.results?.map((client) => (
                    <Tr key={client.id}>
                      <Td>{client.id}</Td>
                      <Td>{client.name}</Td>
                      <Td display="flex" gap="10px">
                        <Button
                          color="white"
                          borderRadius="12px"
                          background="#5772FF"
                          fontSize=" 14px"
                          fontStyle="normal"
                          fontWeight=" 500"
                          lineHeight="normal"
                          h={'37px'}
                          onClick={() => onEditClick(client)}
                        >
                          Edit
                        </Button>
                        <Button
                          color="white"
                          borderRadius="12px"
                          background="#FF6347"
                          fontSize=" 14px"
                          fontStyle="normal"
                          fontWeight=" 500"
                          lineHeight="normal"
                          h={'37px'}
                          onClick={() => {
                            setClientToDelete(client);
                            onOpen();
                          }}
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Box
            display="none"
            justifyContent="space-between"
            padding={['unset', 'unset', '0px 20px']}
            margin="16px 0px 12px 0px"
          >
            <Button
              onClick={handlePrevPage}
              isDisabled={clientsList?.current_page === 1}
            >
              Previous
            </Button>
            <Text>{`Page ${clientsList?.current_page} of ${totalPages}`}</Text>
            <Button
              onClick={handleNextPage}
              isDisabled={clientsList?.current_page === totalPages}
            >
              Next
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
