import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Spinner,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {userLogin, userLoginTelegram} from '../../api/InvestorApis/apis';
import {
    UserLoginPayload,
    UserLogInResponse,
} from '../../interfaces/components';
import {AppStoreState, useAppStore} from '../../store';
import ForgetModal from './ForgetModal';
import {useTelegram} from "../../providers/TelegramProvider";

export default function Login() {
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        defaultValues: {
            wallet_address: '',
            email: '',
            password: '',
            first_name: '',
            last_name: '',
        },
    });
    console.log('erress', errors);
    const navigate = useNavigate();
    const loginApiResponse = useAppStore(
        (state: AppStoreState) => state.loginApiResponse,
    );
    const setLoginApiResponse = useAppStore(
        (state: AppStoreState) => state.setLoginApiResponse,
    );
    const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
    const loading = useAppStore((state: AppStoreState) => state.loading);
    const cTgId = useAppStore(
        (state: AppStoreState) => state.cTgId,
    );
    const {telegram_user} = useTelegram();
    const toast = useToast();
    const handleFormSubmit = async (data: UserLoginPayload) => {
        try {
            setLoading(true);
            let res=null;
            if(cTgId && telegram_user){
                const c_data = {
                    email: data.email,
                    password: data.password,
                    tgId: cTgId,
                    tId: telegram_user?.id?.toString()
                }
                res = await userLoginTelegram(c_data);
            }else {
                res = await userLogin(data);
            }

            if (res) {
                setLoginApiResponse(res as unknown as UserLogInResponse);
                setLoading(false);
                toast({
                    title: 'Login successful!',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                navigate('/');
                reset();
            }
        } catch (error: any) {
            setLoading(false);
            toast({
                title: 'Something went wrong while while logging in.',
                description: error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            console.log('Error While Fetch All Users ', error);
        }
    };

    useEffect(() => {
        loginApiResponse?.data?.access_token &&
        localStorage.setItem(
            'access_token',
            loginApiResponse?.data?.access_token,
        );
        loginApiResponse?.data?.refresh_token &&
        localStorage.setItem(
            'refresh_token',
            loginApiResponse?.data?.refresh_token,
        );
    }, [loginApiResponse, loading]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            {loading ? (
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Spinner/>
                </Box>
            ) : (
                <Box
                    boxShadow={['none', 'none', '0px 0px 10px 0px rgba(0, 0, 0, 0.15)']}
                    padding={['20px 20px', '22px 22px 22px 22px']}
                    borderRadius="36px"
                    border="1px solid #E2E2E2"
                    width={['90vw', '90vw', '500px']}
                    background={'#FFF'}
                >
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <VStack spacing={4}>
                            <Text fontSize="24px" fontFamily="Montserrat" fontWeight="700">
                                Log in
                            </Text>
                            {/* Email */}

                            <FormControl isRequired>
                                <FormLabel
                                    htmlFor="email"
                                    color="#8C8C8C"
                                    fontSize="12px"
                                    fontFamily="Montserrat"
                                >
                                    Username
                                </FormLabel>
                                <Input
                                    borderRadius="12px"
                                    variant="filled"
                                    id="email"
                                    placeholder="Enter your username"
                                    {...register('email', {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: 'Invalid email address',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.email && errors.email.message}
                                </FormErrorMessage>
                            </FormControl>

                            {/* Password */}
                            <FormControl isRequired>
                                <FormLabel
                                    htmlFor="password"
                                    color="#8C8C8C"
                                    fontSize="12px"
                                    fontFamily="Montserrat"
                                >
                                    Password
                                </FormLabel>
                                <Input
                                    borderRadius="12px"
                                    variant="filled"
                                    id="password"
                                    type="password"
                                    placeholder="Enter your password"
                                    {...register('password', {
                                        required: 'Password is required',
                                        minLength: {
                                            value: 6,
                                            message: 'Password must be at least 6 characters',
                                        },
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.password && errors.password.message}
                                </FormErrorMessage>
                            </FormControl>

                            <Text
                                variant="link"
                                color="#8C8C8C"
                                fontSize="12px"
                                fontFamily="Montserrat"
                            >
                                Not a member?&nbsp;
                                <span
                                    style={{
                                        color: '#0FBF95',
                                        fontWeight: '700',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/signup')}
                                >
                  Signup
                </span>
                            </Text>

                            <Button
                                bg="#0FBF95"
                                color="white"
                                type="submit"
                                width="full"
                                fontFamily="Montserrat"
                                borderRadius="14px"
                                fontSize="14px"
                            >
                                Login
                            </Button>

                            <Button
                                variant="link"
                                color="#0FBF95"
                                fontSize="12px"
                                fontFamily="Montserrat"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsModalOpen(true)
                                }}
                            >
                                Forgot password?
                            </Button>
                            <ForgetModal
                                isOpen={isModalOpen}
                                onClose={() => setIsModalOpen(false)}
                            />
                        </VStack>
                    </form>
                </Box>
            )}
        </>
    );
}
