import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React from 'react';
import {
  ClientResultsArray,
  InvestmentResponse,
} from '../../interfaces/components';

interface CustomSelectProps {
  options: ClientResultsArray[] | null;
  value: any;
  placeholder: string;
  setSelectedOption: (option: ClientResultsArray) => void;
  selectedOption: ClientResultsArray | null;
  investmentToEdit: InvestmentResponse | null;
}

export const CustomSelect = (props: CustomSelectProps) => {
  const {
    options,
    placeholder,
    setSelectedOption,
    selectedOption,
    investmentToEdit,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSelect = (option: ClientResultsArray) => {
    setSelectedOption(option);
    onClose();
  };

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        onClick={onOpen}
        borderRadius="12px"
        height="44px"
        width="100%"
        textAlign="left"
        backgroundColor="#F2F2F2"
        _hover={{
          color: '#8C8C8C',
          bg: '#F2F2F2',
        }}
        border="1px solid #E2E8F0"
        color="#8C8C8C"
        fontSize="14px"
        fontWeight="500"
      >
        {selectedOption
          ? selectedOption.name.toUpperCase()
          : investmentToEdit
          ? investmentToEdit?.client?.name
          : placeholder}
      </MenuButton>
      <MenuList
        borderRadius="14px"
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 4px 14px 0px rgba(0, 0, 0, 0.35)"
        padding="12px"
        zIndex={11}
      >
        {options && options.length > 0 ? (
          options.map((option) => (
            <MenuItem
              height="28px"
              padding="4px 8px"
              marginBottom="4px"
              borderRadius="8px"
              background={
                selectedOption && selectedOption?.id === option?.id
                  ? '#F6F6F6'
                  : investmentToEdit?.client?.id === option?.id
                  ? '#F6F6F6'
                  : 'unset'
              }
              color={
                selectedOption && selectedOption?.id === option?.id
                  ? '#0FBF95'
                  : investmentToEdit?.client?.id === option?.id
                  ? '#0FBF95'
                  : 'unset'
              }
              key={option?.id}
              onClick={() => handleSelect(option)}
              fontFamily="Montserrat"
              fontSize="14px"
              fontStyle="normal"
              fontWeight=" 500"
              lineHeight="20px" /* 142.857% */
            >
              {option.name.toUpperCase()}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
