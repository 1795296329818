import axios from '../axios';

export const postLiquidation = async (data: any) => {
  try {
    const endPoint = `/api/liquidation/`;
    const res = await axios.post<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const getLiquidation = async (page:number|null) => {
  try {
    let endPoint = `/api/liquidation/`;
    if (page){
      endPoint = endPoint + `?page=${page}`
    }
    else{
      endPoint = endPoint + `?paginated=0`
    }
    const res = await axios.get<any>(endPoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};
