import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  // InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getClients } from '../../api/InvestorApis/client';
import { postLiquidation } from '../../api/InvestorApis/liquidity';
import { Client } from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';

type Liquidate = {
  payout: any;
  client: any;
  client_name: string;
  token_amount: string;
  claim_value: number;
  sell_value: number;
};

export default function LiquidateForm({
  currentClient,
  payoutId,
  total_claimed,
  unClaimedAmount,
  isOpen,
  onClose,
}: {
  total_claimed: number;
  payoutId: number;
  unClaimedAmount: number;
  currentClient: Client;
  isOpen: boolean;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const setClientsList = useAppStore(
    (state: AppStoreState) => state.setClientsList,
  );
  const mutation = useMutation({
    mutationFn: postLiquidation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['singleClient'] });
      toast({
        title: 'Submission sucessfull',
        description: 'Values have been stored',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });
  const { handleSubmit, register, setValue } = useForm<Liquidate>({
    defaultValues: {
      client_name: '',
      token_amount: '',
      claim_value: 0,
      sell_value: 0,
    },
  });
  console.log('Unclaimed amount = ',unClaimedAmount)
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (data: Liquidate) => {
    setLoading(true);
    console.log('payoutId = ', payoutId);
    data.client = currentClient.id;
    data.payout = payoutId;
    data.claim_value = total_claimed;
    mutation.mutate(data);
    setLoading(false);
    onClose();
  };

  const fetchClients = async (page: number) => {
    try {
      setLoading(true);
      const res = await getClients(page);
      if (res) {
        setClientsList(res);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      console.log('Error While Fetch All Users ', err);
    }
  };
  const setMaxValue = () => {
    setValue('token_amount', unClaimedAmount.toString());
  };
  useEffect(() => {
    fetchClients(1);
  }, []);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        width={['90vw', '80vw', '546px', '546px', '546px']}
        borderRadius="20px"
        padding={['24px', '36px', '36px']}
        height={['auto', 'auto', 'unset']}
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
        maxHeight={['500px', '500px', 'unset']}
        overflowY="scroll"
      >
        <ModalBody padding="0px" position="relative">
          <CloseIcon
            top="0"
            right="0"
            position="absolute"
            boxSize={4}
            onClick={onClose}
            cursor="pointer"
          />

          {loading ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Box>
          ) : (
            <Box>
              <Box>
                <Text
                  color="#1A1A1A"
                  fontFamily="Montserrat"
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="normal"
                >
                  Track your liquidation
                </Text>
              </Box>

              <form
                onSubmit={handleSubmit(handleFormSubmit)}
                style={{ marginTop: '24px' }}
              >
                <VStack spacing={1} align="stretch">
                  <Flex direction="column" gap="12px" marginTop="12px">
                    <FormControl>
                      <Box
                        id="token_amount"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        width="100%"
                        border="1px solid transparent"
                        _hover={{ borderColor: '#E2E8F0' }}
                        padding={'12px 14px 12px 14px'}
                        alignItems={'center'}
                      >
                        <Flex
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'10px'}
                          >
                            <Text
                              fontSize={'14px'}
                              fontWeight={'600'}
                              lineHeight={'12px'}
                              color={'#8C8C8C'}
                              fontFamily={'Montserrat'}
                              fontStyle={'normal'}
                              alignSelf={'start'}
                            >
                              Client
                            </Text>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'600'}
                              lineHeight={'normal'}
                              fontFamily={'Montserrat'}
                              color={'#1A1A1A'}
                              textAlign={'center'}
                              fontStyle={'normal'}
                            >
                              {currentClient.name}
                            </Text>
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                            gap={'10px'}
                          >
                            <Text
                              fontSize={'14px'}
                              fontWeight={'600'}
                              lineHeight={'12px'}
                              color={'#8C8C8C'}
                              fontFamily={'Montserrat'}
                              fontStyle={'normal'}
                            >
                              Available tokens
                            </Text>
                            <Text
                              fontSize={'16px'}
                              fontWeight={'600'}
                              lineHeight={'normal'}
                              fontFamily={'Montserrat'}
                              color={'#39D05D'}
                              alignSelf={'end'}
                              fontStyle={'normal'}
                            >
                              {unClaimedAmount} {currentClient.name}
                            </Text>
                          </Box>
                        </Flex>
                      </Box>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="token_address"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Amount of tokens sold
                      </FormLabel>
                      <InputGroup>
                        <NumberInput width={'100%'}>
                          <InputRightElement
                            onClick={setMaxValue}
                            style={{
                              margin: '0px 12px 5px 0px',
                            }}
                          >
                            <Box
                              display={'flex'}
                              width={'38px'}
                              height={'30px'}
                              background={'#FFF'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              marginTop={'4px'}
                              borderRadius={'4px'}
                              border={
                                '1px solid var(--Color-Neutral-200, #E2E2E2'
                              }
                            >
                              <Text
                                fontFamily={'Montserrat'}
                                fontSize={'12px'}
                                color={'#0FBF95'}
                                fontWeight={'600'}
                                lineHeight={'normal'}
                              >
                                MAX
                              </Text>
                            </Box>
                          </InputRightElement>
                          <NumberInputField
                            id="token_amount"
                            {...register('token_amount')}
                            placeholder="Amount"
                            backgroundColor="#F2F2F2"
                            borderRadius="12px"
                            height="44px"
                            width={'100%'}
                          />
                        </NumberInput>
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="token_address"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Sale value (in USD)
                      </FormLabel>
                      <InputGroup>
                        <NumberInput width={'100%'}>
                          {/* <InputLeftElement pointerEvents="none" style={{
                              margin: '0px 12px 5px 0px',
                            }} >
                          <Box
                            display={'flex'}
                            width={'20px'}
                            height={'20px'}
                            background={'#FFF'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            marginTop={'6px'}
                            borderRadius={'4px'}

                            border={
                              '1px solid var(--Color-Neutral-200, #E2E2E2'
                            }
                          >
                            <Text
                              fontFamily={'Montserrat'}
                              fontSize={'12px'}
                              color={'#8C8C8C'}
                              fontWeight={'500'}
                              lineHeight={'20px'}

                            >
                              $
                            </Text>
                          </Box>
                        </InputLeftElement> */}
                          <NumberInputField
                            id="sell_value"
                            {...register('sell_value')}
                            type="number"
                            placeholder="Amount"
                            backgroundColor="#F2F2F2"
                            borderRadius="12px"
                            height="44px"
                          />
                        </NumberInput>
                      </InputGroup>
                    </FormControl>
                  </Flex>

                  <Box
                    display="flex"
                    gap={['10px', '10px', '14px']}
                    flexDirection={['column-reverse', 'column-reverse', 'row']}
                  >
                    <Button
                      marginTop={['unset', 'unset', '24px']}
                      borderRadius="14px"
                      border="1px solid #8C8C8C"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      onClick={onClose}
                      width={['auto', 'auto', '50%']}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="white"
                      whiteSpace="break-spaces"
                      type="submit"
                      marginTop="24px"
                      width={['auto', 'auto', '50%']}
                      borderRadius="14px"
                      background="#0FBF95"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      _hover={{
                        color: 'white',
                        bg: '#0FBF75',
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </VStack>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
