import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { ClientInfoResponse } from '../../interfaces/components';

interface Investment {
  name: string;
  percentage: number;
  adjustedPercentage: number;
  color: string;
}

const Chart: React.FC<{ clientInfo: ClientInfoResponse | undefined }> = ({
  clientInfo,
}) => {
  // Define exactly 7 colors for 7 investments
  const colors = [
    '#FED134',
    '#BEB9FF',
    '#5654D4',
    '#F9983E',
    '#39D05D',
    'cyan',
    '#D9D9D9',
  ];

  const total_market_price = clientInfo?.total_market_price || 0;

  const allInvestments: Omit<Investment, 'adjustedPercentage'>[] =
    useMemo(() => {
      //////////////////////
      if (!clientInfo?.results) return [];

      // Get all investments with their percentages
      const investments = clientInfo.results.map((client) => {
        const percentage =
          (client.total_market_price / total_market_price) * 100;
        return {
          name: client.client.name,
          percentage: parseFloat(percentage.toFixed(4)) || 0,
          color: '', // We'll assign colors after sorting
        };
      });
      // Sort investments by percentage in descending order
      const sortedInvestments = [...investments].sort(
        (a, b) => b.percentage - a.percentage,
      );

      // Take first 6 investments and assign colors
      const topInvestments = sortedInvestments
        .slice(0, 6)
        .map((inv, index) => ({
          ...inv,
          color: colors[index], // Assign color based on position in top investments
        }));

      // Combine remaining investments into "Others"
      const remainingInvestments = sortedInvestments.slice(6);
      if (remainingInvestments.length > 0) {
        const othersPercentage = remainingInvestments.reduce(
          (sum, inv) => sum + inv.percentage,
          0,
        );

        topInvestments.push({
          name: 'Others',
          percentage: parseFloat(othersPercentage.toFixed(0)),
          color: colors[6], // Use the last color (gray) for Others
        });
      }

      return topInvestments;
    }, [clientInfo, total_market_price]);

  const [currentDate, setCurrentDate] = useState<string>('');

  useEffect(() => {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    const formattedDate = today.toLocaleDateString('en-US', options);
    setCurrentDate(formattedDate);
  }, []);

  const adjustedInvestments = useMemo(() => {
    const totalPercentage = allInvestments.reduce(
      (sum, inv) => sum + inv.percentage,
      0,
    );
    const scaleFactor = 100 / totalPercentage;

    return allInvestments?.map((inv) => ({
      ...inv,
      adjustedPercentage: +(inv.percentage * scaleFactor).toFixed(2),
    }));
  }, [allInvestments]);

  const highestValueInvestment = useMemo(() => {
    return adjustedInvestments.reduce(
      (max, current) => (current.percentage > max.percentage ? current : max),
      adjustedInvestments[0],
    );
  }, [adjustedInvestments]);

  const [hoveredInvestment, setHoveredInvestment] = useState<Investment | null>(
    highestValueInvestment,
  );

  let startAngle = 0;
  const gap = 1.5;

  return (
    <Box
      borderBottomRadius={['0px ', '0px', '44px']}
      // border={['transparent', 'transparent', '1px solid #EFEFEF']}
      borderTop={'transparent'}
      background={'#FFF'}
      borderBottom={['1px solid #EFEFEF', '1px solid #EFEFEF', 'none']}
      boxShadow={[
        'none',
        'none',
        '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
      ]}
      padding={'22px'}
      height={['none', 'none', 'none', '519px']}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'24px'}
        padding={['0px', '0px', '24px 24px 0px 24px']}
      >
        <Flex gap={'8px'} justifyContent={'center'}>
          <Text
            fontSize={['13.5px', '14px', '14px', '16px']}
            fontWeight={'500'}
            lineHeight={'24px'}
            fontFamily="Montserrat"
          >
            total investments {clientInfo?.results.length || 0}
          </Text>
          <Text
            fontSize={['13.5px', '14px', '14px', '16px']}
            fontWeight={'500'}
            lineHeight={'24px'}
            color="#878787"
            fontFamily="Montserrat"
          >
            Today, {currentDate}.
          </Text>
        </Flex>

        <Flex
          gap={['24px', '24px', '24px', '56px']}
          flexDir={['column', 'column', 'column', 'row']}
        >
          <Flex
            justify="center"
            align="center"
            position="relative"
            padding={['14.6px', '14.6px', '14.6px', '0px']}
          >
            <svg viewBox="0 0 100 100" width="100%" height="100%">
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="transparent"
                strokeWidth="10"
              />
              {adjustedInvestments.map((investment, index) => {
                const angle =
                  (investment.adjustedPercentage / 100) *
                  (360 - gap * adjustedInvestments.length);
                const endAngle = startAngle + angle;

                const start = polarToCartesian(50, 50, 45, startAngle - 90);
                const end = polarToCartesian(50, 50, 45, endAngle - 90);

                const largeArcFlag = angle > 180 ? 1 : 0;

                const path = [
                  'M',
                  start.x,
                  start.y,
                  'A',
                  45,
                  45,
                  0,
                  largeArcFlag,
                  1,
                  end.x,
                  end.y,
                ].join(' ');

                startAngle = endAngle + gap;

                return (
                  <path
                    key={index}
                    d={path}
                    fill="none"
                    stroke={investment.color}
                    strokeWidth="10"
                    onMouseEnter={() => setHoveredInvestment(investment)}
                    onMouseLeave={() =>
                      setHoveredInvestment(highestValueInvestment)
                    }
                    style={{ cursor: 'pointer' }}
                  />
                );
              })}
            </svg>
            <VStack position="absolute" spacing={0} textAlign="center">
              <Text
                fontSize="28px"
                fontWeight="700"
                lineHeight={'41.181px'}
                color={
                  (hoveredInvestment?.color ?? '') ||
                  (highestValueInvestment?.color ?? '')
                }
              >
                +
                {hoveredInvestment
                  ? `${hoveredInvestment?.percentage.toFixed(2)}%`
                  : `${highestValueInvestment?.percentage}%`}
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                color={'#080808'}
                lineHeight={'19.007px'}
              >
                {hoveredInvestment?.name || highestValueInvestment?.name}
              </Text>
              <Text
                fontSize="14px"
                color="#878787"
                fontWeight={'500'}
                lineHeight={'23.75px'}
              >
                Proportional
              </Text>
            </VStack>
          </Flex>

          <VStack
            align="stretch"
            width={'323px'}
            spacing={'12px'}
            justify="center"
          >
            {adjustedInvestments.map((investment, index) => (
              <HStack
                key={index}
                justifyContent={'space-between'}
                margin={'0px'}
                onMouseEnter={() => setHoveredInvestment(investment)}
                onMouseLeave={() =>
                  setHoveredInvestment(highestValueInvestment)
                }
                style={{ cursor: 'pointer' }}
              >
                <HStack>
                  <Box
                    w={'6px'}
                    h={'12px'}
                    borderRadius={'50px'}
                    bg={investment.color}
                  />
                  <Text fontSize="14px" color={'#878787'} fontWeight={'500'}>
                    {investment.name}
                  </Text>
                </HStack>
                <Text fontSize="14px" fontWeight="600" color={'#080808'}>
                  {investment.percentage.toFixed(2)}%
                </Text>
              </HStack>
            ))}
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
};

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

export default Chart;
