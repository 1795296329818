import {
  ClientInfoResponse,
  PayoutResponse,
  PayoutResultsArray,
  UserLoginPayload, UserLoginTgPayload,
  UserSignUpPayload,
} from '../../interfaces/components';
import axios from '../axios';

export const userSignUp = async (data: UserSignUpPayload) => {
  try {
    const endPoint = `api/user/account/`;
    const res = await axios.post<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const userLogin = async (data: UserLoginPayload) => {
  try {
    const endPoint = `api/user/login/`;
    const res = await axios.post<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};
export const userLoginTelegram = async (data: UserLoginTgPayload) => {
  try {
    const endPoint = `api/user/login/?auth_type=TELEGRAM`;
    const res = await axios.post<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};
export const getPayouts = async (paginated:boolean,page: number) => {
  try {
    const endPoint = `api/payouts/?paginated=${paginated? 1:0}&page=${page}`;
    console.log("endpoint = ",endPoint)
    const res = await axios.get<PayoutResponse>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
export const fetchClientsInfo = async () => {
  try {
    const endPoint = `/api/clients/fetchClientsInfo/`;
    const res = await axios.get<ClientInfoResponse>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};


export const getPayoutsById = async (id:number) => {
  try {
    const endPoint = `api/payouts/?id=${id}`;
    const res = await axios.get<PayoutResultsArray>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const getProjectList = async (query: string, page: number) => {
  try {
    const endPoint = `api/root/project-list?query=${encodeURIComponent(
      query,
    )}&page=${page}`;
    const res = await axios.get<Array<any>>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const claimToken = async ({id, data}:{id:string,data:any}) => {
  try {
    console.log("id = ",id)
    console.log('data = ',data)
    const endPoint = `api/payouts/${id}/claim/`;
    const res = await axios.post<any>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const getCurrentUser = async () => {
  try {
    const endPoint = `api/user/current/`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};
