import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

Chart.register(LineElement, CategoryScale, LinearScale, PointElement);

// Generate month labels for the last `count` months
//@ts-ignore

const generateMonthLabels = (count) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const labels = [];
  const currentMonthIndex = new Date().getMonth();

  for (let i = 0; i < count; i++) {
    const month = months[(currentMonthIndex + i) % 12];
    labels.push(month);
  }

  return labels;
};

const LineChart = () => {
  const [liquidateData, setLiquidateData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Replace API call with dummy data
  const fetchDummyData = () => {
    const dummyData = [
      { profit: 150 },
      { profit: 200 },
      { profit: -50 },
      { profit: 100 },
      { profit: 250 },
      { profit: 0 },
      { profit: -100 },
      { profit: 300 },
      { profit: 400 },
      { profit: 350 },
    ];
    //@ts-ignore

    setLiquidateData(dummyData);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchDummyData(); // Fetch dummy data on component mount
  }, []);

  const data = {
    labels: generateMonthLabels(10), // Generate labels for the last 10 months
    datasets: [
      {
        label: 'Profit/Loss',
        //@ts-ignore

        data: liquidateData.map((item) => item.profit), // Extract profit data
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Line Chart Example</h2>
      <Line data={data} />
    </div>
  );
};

export default LineChart;
