import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { claimToken } from '../../api/InvestorApis/apis';
import {
  ClaimTokenModalProps,
  ClaimTokenPayload,
} from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';

export default function ClaimTokenModal({
  isOpen,
  onClose,
  payoutId,
}: ClaimTokenModalProps) {
  const { register, handleSubmit, reset } = useForm<ClaimTokenPayload>();
  const query = useQueryClient();

  const toast = useToast();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const mutation = useMutation({
    mutationFn: claimToken,
    onSuccess: () => {
      query.invalidateQueries({ queryKey: ['singleClient'] });
      toast({
        title: 'Submission sucessfull',
        description: 'Values have been stored',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    },
  });
  const handleFormSubmit = async (data: ClaimTokenPayload) => {
    {
      try {
        setLoading(true);
        mutation.mutate({ id: payoutId, data: { dollar_value: data.amount } });
        reset();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast({
          title: 'Something went wrong while adding claim.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '404px', '404px', '404px']}
        borderRadius="20px"
        padding="36px"
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalCloseButton />
        <ModalBody padding="0px">
          {loading ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Box>
          ) : (
            <Box>
              <Box>
                <Text
                  color="#1A1A1A"
                  fontFamily="Montserrat"
                  fontSize={['16px', '28px', '20px']}
                  fontStyle=" normal"
                  fontWeight=" 700"
                  lineHeight=" normal"
                >
                  Claim Token
                </Text>
              </Box>

              <form
                onSubmit={handleSubmit(handleFormSubmit)}
                style={{ marginTop: '24px' }}
              >
                <VStack spacing={1} align="stretch">
                  <FormControl>
                    <FormLabel
                      htmlFor="amount"
                      color="#8C8C8C"
                      fontSize="12px"
                      fontFamily="Montserrat"
                    >
                      Token in dollars
                    </FormLabel>
                    <NumberInput variant="filled" defaultValue={0} min={0}>
                      <NumberInputField
                        borderRadius="12px"
                        placeholder="Enter dollar amount"
                        id="amount"
                        type="number"
                        {...register('amount', {
                          required: 'Add token amount',
                        })}
                      />
                    </NumberInput>

                    {/* <FormErrorMessage>{errors.first_name && errors.first_name.message}</FormErrorMessage> */}
                  </FormControl>

                  <Box display="flex" gap="14px">
                    <Button
                      marginTop="24px"
                      width="161px"
                      borderRadius="14px"
                      border=" 1px solid #8C8C8C"
                      background="#FFF"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      onClick={() => onClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="white"
                      whiteSpace="break-spaces"
                      type="submit"
                      marginTop="24px"
                      width="161px"
                      borderRadius="14px"
                      background="#0FBF95"
                      fontWeight={600}
                      fontSize="14px"
                      fontFamily="Montserrat"
                      _hover={{
                        color: 'white',
                        bg: '#0FBF75',
                      }}
                    >
                      Claim
                    </Button>
                  </Box>
                </VStack>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
